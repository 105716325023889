import React from "react";
import Layout from "../layout/Layout";
import SubHeader from "../components/SubHeader";
import { useState, useEffect, useContext } from "react";
import { setAppElement } from "react-modal";
import CommonModal from "../components/CommonModal";
import { useDispatch, useSelector } from "react-redux";
import ProductContent from "../containers/ProductContent";
import FilterBearbeiten from "../containers/FilterBearbeiten";
import AsinsModal from "../containers/AsinsModal";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import NoProductsData from "../containers/NoProductsData";
import {
  fetchA2AProducts,
} from "../redux/slices/a2aProductSlice";
import {
  changeFilter,
  currentFilter,
  fetchFilter
} from "../redux/slices/filterSlice";
import { findObject } from "../utlis/utlis";
import FiterMultiDropdown from "../containers/FilterMenu/FiterMultiDropdown";
import FilterDropdown from "../containers/FilterMenu/FilterDropdown";
import FiterMultiKeyword from "../containers/FilterMenu/FiterMultiKeyword";
import FilterKeyword from "../containers/FilterMenu/FilterKeyword";
import { ThemeContext } from "../context/themeContext";
import { useLocation } from "react-router-dom";

const ProductList = ({
  application,
  productType,
  header,
  subHeader,
  filterSubHeader,
  noProductbtnTitle,
  noProductDesc,
  priceAlertInfoName,
  addPriceAlertName,
  deletePriceAlertName,
  asinsModalHeader,
  asinsModalSubHeader,
  isAsinsModalHide
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const productDetails = useSelector((state) => state.a2aProducts.a2aProducts);

  const authStatus = useSelector((state) => state.a2aProducts.authStatus);
  useCheckUserAuth(authStatus);

  const filterDetails = useSelector((state) => state.filter.filter);
  const currentFilterDetails = useSelector(
    (state) => state.filter.currentFilter
  );
  const changeFilterDetails = useSelector((state) => state.filter.changeFilter);

  const [isOpen, setIsOpen] = useState(false);
  const [asinsModal, setAsinsModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [newProductData, setNewProductData] = useState([]);
  const [filterValue, setFilterValue] = useState({});
  const [filterMenuDetails, setFilterMenuDetails] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [filterValueChanged, setFilterValueChanged] = useState(false);
  const [isAsinsLoader, setIsAsinsLoader] = useState(false);
  const [isProductFetch, setIsProductFetch] = useState(false);
  const [loading, setLoading] = useState(false);

  const { theme } = useContext(ThemeContext);
  const customStyles = {
    backgroundImage:
      theme === "dark"
        ? "linear-gradient(357deg, rgb(61 61 69) -135.67%, rgb(8 7 7) 68.75%)"
        : "linear-gradient(0deg, rgb(255 255 255 / 5%) -135.67%, rgb(255 255 255) 68.75%)",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"
  };

  useEffect(() => {
    setAppElement("#root");
    dispatch(fetchFilter(application));
    dispatch(currentFilter(application));
    setLoading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        productDetails?.products &&
        filterDetails?.filter &&
        currentFilterDetails?.filter
      ) {
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [productDetails, filterDetails, currentFilterDetails]);

  useEffect(() => {
    setIsProductFetch(false);
  }, [location.pathname]);

  useEffect(() => {
    setIsLoader(true);
    const payload = { productType, offSet };
    dispatch(fetchA2AProducts(payload));
    setIsProductFetch(true);
  }, [offSet]);

  useEffect(() => {

    if (
      isLoader &&
      isProductFetch &&
      productDetails &&
      productDetails.products
    ) {

      setProductData((prev) => [...prev, ...productDetails.products]);
      setNewProductData(productDetails.products);
      setIsLoader(false);
    }
  }, [productDetails]);

  const handleAsinsModal = () => {
    setAsinsModal(true);
  };

  const handleAsinsClose = () => {
    setAsinsModal(false);
  };

  const handleAmazonFlipsClose = () => {
    setIsOpen(false);
    filterValueChanged && window.location.reload();
  };

  const handleAmazonFlips = () => {
    setIsOpen(true);
    setIsAsinsLoader(true);
  };

  useEffect(() => {
    if (filterDetails && filterDetails.filter) {
      setFilterMenuDetails(filterDetails.filter);

      if (currentFilterDetails && currentFilterDetails.filter) {
        const currentFilterData = currentFilterDetails.filter.reduce(
          (acc, item) => {
            return { ...acc, ...item };
          },
          {}
        );
        const output = findObject(
          filterDetails.filter.filter,
          currentFilterData
        );
        setFilterValue(output);
        setIsAsinsLoader(false);
      }
    }
  }, [filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (!isOpen && changeFilterDetails && changeFilterDetails.message) {
      window.location.reload();
    }
  }, [changeFilterDetails]);

  useEffect(() => {
    if (changeFilterDetails && changeFilterDetails.message) {
      setFilterValueChanged(true);
    }
  }, [changeFilterDetails]);

  const handleChangeDropdown = (item, selectedItem) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: selectedItem
    }));

    const filterData = {
      application: application,
      type: item.type,
      attribute: selectedItem.value
    };
    dispatch(changeFilter(filterData));
  };

  const handleChangeMultiDropdown = (item, value) => {
    let filterData = {};

    setFilterValue((prev) => ({
      ...prev,
      [item.type]: value
    }));

    if (value.length === 0 || value.length < filterValue[item.type].length) {
      const removedValue = filterValue[item.type].filter(
        (x) => value.indexOf(x) < 0
      );
      filterData = {
        application: application,
        type: item.type,
        attribute: removedValue.map((x) => x.value)
      };
    } else {
      const latestItem = value[value.length - 1];

      filterData = {
        application: application,
        type: item.type,
        attribute: latestItem.value
      };
    }
    dispatch(changeFilter(filterData));
  };

  const handleChangeKeyword = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const oldValue = filterValue[name].value;

    if (!isNaN(value)) {
      setFilterValue((prev) => ({
        ...prev,
        [name]: { value: value, oldValue: oldValue }
      }));
    }
  };

  const handleBlur = (e, item) => {
    const value = e.target.value;
    const name = e.target.name;
    const oldValue = filterValue[name].oldValue;

    if (value) {
      const num = parseFloat(value);
      let newValue = value;

      if (!isNaN(value)) {
        newValue = num.toFixed(2) + item.unit;

        setFilterValue((prev) => ({
          ...prev,
          [name]: { value: newValue, oldValue: oldValue }
        }));

        if (Number(value) !== oldValue) {
          const filterData = {
            application: application,
            type: name,
            attribute: value
          };
          dispatch(changeFilter(filterData));
        }
      }
    } else {
      if (!value && oldValue) {
        const filterData = {
          application: application,
          type: name,
          attribute: oldValue
        };
        dispatch(changeFilter(filterData));
      }
    }
  };

  const handleFocus = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === "") return;
    const newValue = parseFloat(value.replace(/%$/, ""));

    setFilterValue((prev) => ({
      ...prev,
      [name]: { value: newValue, oldValue: newValue }
    }));
  };

  const handleEnterItem = (item, value) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: [...prev[item.type], { value: value, label: value }]
    }));

    const filterData = {
      application: application,
      type: item.type,
      attribute: value
    };

    dispatch(changeFilter(filterData));
  };

  const handleChangeMultiKeyword = (item, value) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: value
    }));

    if (value.length === 0 || value.length < filterValue[item.type].length) {
      const removedValue = filterValue[item.type].filter(
        (x) => value.indexOf(x) < 0
      );
      const filterData = {
        application: application,
        type: item.type,
        attribute: removedValue.map((x) => x.value)
      };
      dispatch(changeFilter(filterData));
    }
  };

  const renderDropdownContent = (item, i) => {
    if (item.filter_bar) {
      if (item.input_type === "dropdown") {
        if (item.multiple_selection) {
          return (
            <FiterMultiDropdown
              key={i}
              item={item}
              filterValue={filterValue}
              onChange={handleChangeMultiDropdown}
            />
          );
        } else {
          return (
            <FilterDropdown
              key={i}
              options={item.values.map((option) => ({
                label: option.value,
                value: option.request_value
              }))}
              placeholder={item.name}
              onChange={(value) => handleChangeDropdown(item, value)}
              value={filterValue[item.type]}
              width={"110px"}
            />
          );
        }
      } else {
        if (item.multiple_selection) {
          return (
            <FiterMultiKeyword
              key={i}
              item={item}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleEnterItem={handleEnterItem}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              onChange={handleChangeMultiKeyword}
            />
          );
        } else {
          return (
            <FilterKeyword
              key={i}
              item={item}
              onChange={handleChangeKeyword}
              handleBlur={(e) => handleBlur(e, item)}
              handleFocus={handleFocus}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        }
      }
    }
  };

  return (
    <>
      <Layout>
        <section className="home-section bg-background no-scrollbar">
          <div className="text border-b-[1px] border-borderBottom">
            <SubHeader date>
              <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
                {header}
                <span className="text-primary ml-1 font-semibold tracking-wide">
                  {subHeader}
                </span>
              </p>
            </SubHeader>
          </div>

          <div className="flex flex-wrap gap-6 pb-2 pt-2 px-4 md:px-10 product-drp-header">
            {filterMenuDetails["filter"] &&
              filterMenuDetails["filter"].map((item, index) =>
                renderDropdownContent(item, index)
              )}

            {filterMenuDetails.all_filters_box && (
              <div className="dropdown">
                <button
                  className="dropbtn border-[1px] border-borderBottom text-sm font-medium capitalize shadow-md w-[110px]"
                  style={customStyles}
                  onClick={handleAmazonFlips}
                >
                  Alle Filter &nbsp;{" "}
                  <i className="bx bx-filter text-[16px] text-textPrimary"></i>
                </button>
              </div>
            )}
            {!isAsinsModalHide && (
              <div className="dropdown">
                <button
                  className="dropbtn hover:border-primary  bg-secondary  text-primary cursor-pointer text-sm border-[1px] border-primary font-medium flex pr-2 shadow capitalize"
                  onClick={handleAsinsModal}
                >
                  Asins bearbeiten &nbsp;
                  <i className="bx bx-plus text-[18px]"></i>
                </button>
              </div>
            )}
          </div>

          {productData.length > 0 ? (
            <div className="px-10">
              <section className="gap-4">
                <ProductContent
                  productData={productData}
                  setOffSet={setOffSet}
                  newProductData={newProductData}
                  isLoader={isLoader}
                />
              </section>
            </div>
          ) : (
            <NoProductsData
              btnTitle={noProductbtnTitle}
              description={noProductDesc}
            />
          )}
          {loading && (
            <div className="spinner">
              <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
            </div>
          )}
        </section>

        <CommonModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeModal={handleAmazonFlipsClose}
        >
          <FilterBearbeiten
            closeModal={handleAmazonFlipsClose}
            filterMenuDetails={filterMenuDetails}
            handleChangeDropdown={handleChangeDropdown}
            filterValue={filterValue}
            handleChangeKeyword={handleChangeKeyword}
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleChangeMultiDropdown={handleChangeMultiDropdown}
            handleEnterItem={handleEnterItem}
            handleChangeMultiKeyword={handleChangeMultiKeyword}
            setFilterValue={setFilterValue}
            isAsinsLoader={isAsinsLoader}
            application={application}
            filterSubHeader={filterSubHeader}
          />
        </CommonModal>

        <CommonModal
          isOpen={asinsModal}
          setIsOpen={setAsinsModal}
          closeModal={handleAsinsClose}
        >
          <AsinsModal
            closeModal={handleAsinsClose}
            priceAlertInfoName={priceAlertInfoName}
            addPriceAlertName={addPriceAlertName}
            deletePriceAlertName={deletePriceAlertName}
            asinsModalHeader={asinsModalHeader}
            asinsModalSubHeader={asinsModalSubHeader}
          />
        </CommonModal>
        
      </Layout>
    </>
  );
};
export default ProductList;
