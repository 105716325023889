import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../context/themeContext";

const LoginComponent = () => {
  const loginData = useSelector((state) => state.login.data);

  const { theme } = useContext(ThemeContext);

  const impressumLink = "https://www.edgeecom.de/legal/imprint";
  const datenschutzLink = "https://www.edgeecom.de/datenschutz";
  const agbLink = "https://www.edgeecom.de/agb";

  const imageSrc =
    theme === "dark" ? "static/bg-flare-dark.svg" : "static/bg-flare-light.svg";

  return (
    <div
      style={{
        backgroundImage: `url(${imageSrc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center"
      }}
    >
      <div className="teal-effect"></div>
      <div className="blue-effect"></div>

      <div className="flex h-screen text-textTitle flex-col items-center gap-12 justify-center">
        <div className="flex border-[1px] border-borderBottom rounded-lg flex-col items-center gap-12 justify-center px-10 shadow pb-4 pt-20">
          <img
            src={
              theme === "dark"
                ? "static/darkmode-logo.svg"
                : "static/lightmode-logo.svg"
            }
            alt="logo"
            className="w-full"
          />

          <div className="text-left">
            <p className="text-[22px] tracking-wider text-primary">Anmelden</p>
            <p className="text-[14px] text-textPrimary tracking-wider w-[260px] font-light">
              Mit der Verifizierung durch Discord fortfahren und anmelden
            </p>
          </div>

          <Link to={loginData?.login_url}>
            <button className="bg-gradient-to-br from-primary to-main text-[16px] cursor-pointer text-text w-72 p-2 font-medium rounded">
              Anmelden
            </button>
          </Link>
          <div className="divide-x divide-textsecondary">
            <a
              target="_blank"
              href={impressumLink}
              className="text-textsecondary pr-3 text-[12px] tracking-wider"
              rel="noreferrer"
            >
              Impressum
            </a>
            <a
              target="_blank"
              href={agbLink}
              className="text-textsecondary px-3 text-[12px] tracking-wider"
              rel="noreferrer"
            >
              AGB
            </a>
            <a
              target="_blank"
              href={datenschutzLink}
              className="text-textsecondary pl-3 text-[12px] tracking-wider"
              rel="noreferrer"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
