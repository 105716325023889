import React from "react";
import { useNavigate } from "react-router-dom";

const Card = ({ title, icon, link, selectCard, desc }) => {
  const navigate = useNavigate();

  const handleStarten = (link) => {
    navigate(`/${link}`);
  };

  return (
    <div
      className={`guides flex bg-bgColor border-[1px] border-borderBottom h-[284px] shadow ${
        selectCard ? "bg-gradient-to-br from-primary to-main gap-3" : "gap-6"
      }`}
    >
      <p
        className={` text-text font-bold ${
          selectCard
            ? "guide-select-title text-[24px] sm:text-[28px] p-0 font-[500px]"
            : "guide-title bg-gradient-to-br from-primary to-main text-transparent text-[24px] sm:text-[28px] font-semibold p-0"
        }`}
        style={{ backgroundClip: "text" }}
      >
        {title}
      </p>

      {selectCard ? (
        <p
          className="text-[84px] bg-gradient-to-br from-background via-secondary to-background text-transparent font-semibold tracking-wide"
          style={{ backgroundClip: "text" }}
        >
          {desc}
        </p>
      ) : (
        <img className="h-[100px]" src={icon} alt="img" />
      )}

      {selectCard ? (
        <p
          className={`text-base text-text tracking-wider text-[14px] font-bold	${
            selectCard ? "guide-select-title" : "guide-title"
          }`}
        >
          {link}
        </p>
      ) : (
        <button
          className="btn-doc bg-secondary text-primary tracking-wide"
          onClick={() => handleStarten(link)}
        >
          <p
            className=" bg-gradient-to-br from-primary to-main text-transparent text-[16px] font-[700] px-1"
            style={{ backgroundClip: "text" }}
          >
            starten
          </p>
        </button>
      )}
    </div>
  );
};

export default Card;
