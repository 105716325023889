import React from "react";
import { ThemeContext } from "../context/themeContext";
import { useContext } from "react";
import SubHeader from "./SubHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const imageSrc =
    theme === "dark" ? "static/error-1.svg" : "static/error-2.svg";

  return (
    <Layout>
      <section className="home-section bg-background">
        <div className="text border-b-[1px] border-borderBottom">
          <SubHeader date>
            <p className="title text-textTitle">
              Es gab einen
              <span className="text-primary ml-1">Fehler!</span>
            </p>
          </SubHeader>
        </div>
        <div className="mt-20 flex justify-center items-center flex-col gap-4">
          <div className="flex justify-center items-center">
            <img
              src={imageSrc}
              alt="AmazonFlipsNoSellersEmpty"
              className="w-[500px]"
            />
          </div>

          <div className="flex justify-center items-center mt-[30px] mr-[50px]">
            <p className="text-textColor text-center md:text-[18px] max-w-[390px] text-[16px] tracking-wider font-medium">
              Scheinbar gab es einen Fehler, kehre zur Übersicht zurück oder
              lade diese Seite neu!
            </p>
          </div>
          <div className="flex justify-center items-center mr-[50px]">
            <button
              className="p_detail px-6 py-2 text-[14px] text-text border-none 
          capitalize bg-gradient-to-br from-primary to-main rounded text-center  mt-4 tracking-widest font-[600]"
              onClick={() => navigate("/home")}
            >
              Startseite
            </button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ErrorPage;
