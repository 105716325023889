import React, { useContext, useEffect, useState } from "react";
import Card from "../components/Card";
import SubHeader from "../components/SubHeader";
import { ThemeContext } from "../context/themeContext";
import { selectCard } from "../lib/mock/overviewMock";
import { useSelector } from "react-redux";
import { overViewMockData } from "../lib/mock/overviewMock";

const Overview = () => {
  const { theme } = useContext(ThemeContext);
  const { firstTimeLoad, setFirstTimeLoad } = useContext(ThemeContext);

  const overViewDetails = useSelector((state) => state.overview.overview);
  const homeDetails = useSelector((state) => state.auth.homeData);

  const [overViewData, setOverViewData] = useState(overViewMockData);

  useEffect(() => {
    if (overViewDetails && overViewDetails.length > 0) {
      setOverViewData(overViewDetails);
    }
  }, [overViewDetails]);

  useEffect(() => {
    if (homeDetails?.status === "authorized" && firstTimeLoad) {
      setTimeout(() => {
        setFirstTimeLoad(false);
      }, 1000);
    }
  }, [homeDetails, firstTimeLoad]);

  return (
    <section className="home-section bg-background">
      <div className="text  border-b-[1px] border-borderBottom">
        <SubHeader date>
          <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
            Willkommen
            <span className="text-primary ml-1 font-semibold tracking-wide">
              {homeDetails?.name}
            </span>
            <span className="ml-1">👋</span>
          </p>
        </SubHeader>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 mx-10 pt-8 mb-10">
        {selectCard.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            link={item.link}
            desc={homeDetails?.deals_today}
            selectCard
          />
        ))}
        {overViewData.map((item) => (
          <Card
            key={item.title}
            title={item.title}
            icon={theme === "dark" ? item.icon : item.iconLight}
            link={item.link}
          />
        ))}
      </div>
      {firstTimeLoad && (
        <div className="spinner">
          <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
        </div>
      )}
    </section>
  );
};

export default Overview;
