import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AuthGuard = () => {
  const authStatus = useSelector((state) => state.auth.status);

  if (authStatus === "loading") {
    return (
      <div className="spinner">
        <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
      </div>
    );
  }

  const isAuthenticated = authStatus !== "unauthorized";

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default AuthGuard;
