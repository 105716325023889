import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getA2AProductsAPI } from "../../lib/api/api";

export const fetchA2AProducts = createAsyncThunk(
  "products/getA2AProductsAPI",
  async (payload, { dispatch }) => {
    const { productType, offSet } = payload;

    const response = await getA2AProductsAPI(productType, offSet);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }

    return response;
  }
);

const a2aProductSlice = createSlice({
  name: "products",
  initialState: {
    a2aProducts: null,
    status: "idle",
    error: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
    resetA2AProducts: (state) => {
      state.a2aProducts = null;
      state.status = "idle";
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchA2AProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchA2AProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.a2aProducts = action.payload;
      })
      .addCase(fetchA2AProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});
export const { setAuthStatus, resetA2AProducts } = a2aProductSlice.actions;
export default a2aProductSlice.reducer;
