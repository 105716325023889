export const valueSign = (value) => {
  if (value === "margin" || value === "margin_30d" || value === "margin_30d") {
    return "%";
  } else if (
    value === "profit" ||
    value === "profit_30d" ||
    value === "buy_price" ||
    value === "sell_price" ||
    value === "30d_avg_sale" ||
    value === "maximum_cost"
  ) {
    return "€";
  }
};

export const convertToDomain = (url) => {
  const domain = new URL(url).hostname;
  const mainDomain = domain.split(".").slice(-2).join(".");
  return mainDomain;
};

export const currencyFormatter = (value) => {
  return typeof value === "number"
    ? new Intl.NumberFormat("de-DE", { currency: "EUR" }).format(value)
    : value;
};

export const formatDateToGerman = (dateString) => {
  const date = new Date(dateString);
  const germanMonths = [
    "jan",
    "feb",
    "mär",
    "apr",
    "mai",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dez"
  ];

  const day = date.getDate();
  const month = date.getMonth();
  return `${germanMonths[month]} ${day}`;
};

export const findObject = (inputArray, searchObject) => {
  const result = {};

  for (const obj of inputArray) {
    const key = obj.type;
    if (searchObject.hasOwnProperty(key)) {
      const value = searchObject[key];

      if (obj.input_type === "dropdown") {
        if (obj.multiple_selection) {
          if (!value) {
            result[key] = [];
            continue;
          }

          if (typeof value === "string") {
            result[key] = value;
            continue;
          }

          const data = value.map((reqVal) =>
            obj.values.find((item) => item.request_value === reqVal)
          );

          result[key] = data.map((item) => ({
            label: item.value,
            value: item.request_value
          }));
        } else {
          const foundValue = obj.values.find(
            (item) => item.request_value === value
          );
          if (foundValue) {
            result[key] = {
              label: foundValue.value,
              value: value
            };
          }
        }
      } else if (obj.input_type === "text") {
        if (obj.multiple_selection) {
          if (!value) {
            result[key] = [];
            continue;
          }

          result[key] = value.map((item) => ({
            label: item,
            value: item
          }));
        } else {
          const num = parseFloat(value);
          let newValue = value;

          if (value && !isNaN(value)) {
            newValue = num.toFixed(2) + obj.unit;
            result[key] = {
              value: newValue
            };
          } else {
            result[key] = {
              value: value
            };
          }
        }
      }
    }
  }

  return result;
};

export const timestamp = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate().toString().padStart(2, "0");
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const year = newDate.getFullYear().toString().slice(-2);
  const hours = newDate.getHours().toString().padStart(2, "0");
  const minutes = newDate.getMinutes().toString().padStart(2, "0");

  // let period = "AM";
  // if (hours >= 12) {
  //   period = "PM";
  //   if (hours > 12) {
  //     hours = String(hours - 12).padStart(2, "0");
  //   }
  // }

  const timestamp = `${day}.${month}.${year} | ${hours}:${minutes}`;
  return timestamp;
};
