import React from "react";
import { useState } from "react";
import { setAppElement } from "react-modal";
import { useEffect } from "react";
import Layout from "../layout/Layout";
import SpyProductCard from "../containers/SpyProductCard";
import ProductRightCard from "../containers/ProductRightCard";
import SubHeader from "../components/SubHeader";
import CommonModal from "../components/CommonModal";
import { fetchProducts } from "../redux/slices/productsSlice";
import { useDispatch, useSelector } from "react-redux";
import NoSellersEmpty from "../containers/NoSellersEmpty";
import SellerTracking from "../containers/SellerTracking";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import {
  changeFilter,
  currentFilter,
  fetchFilter
} from "../redux/slices/filterSlice";
import { findObject } from "../utlis/utlis";
import FilterDropdown from "../containers/FilterMenu/FilterDropdown";
import FiterMultiDropdown from "../containers/FilterMenu/FiterMultiDropdown";
import FiterMultiKeyword from "../containers/FilterMenu/FiterMultiKeyword";
import FilterKeyword from "../containers/FilterMenu/FilterKeyword";

const SpySearch = () => {
  const application = "spy";

  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.products.products);
  const filterDetails = useSelector((state) => state.filter.filter);
  const currentFilterDetails = useSelector(
    (state) => state.filter.currentFilter
  );
  const changeFilterDetails = useSelector((state) => state.filter.changeFilter);

  const authStatus = useSelector((state) => state.products.authStatus);
  useCheckUserAuth(authStatus);

  const [isOpen, setIsOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [offSet, setOffSet] = useState(0);
  const [isSelected, setIsSelected] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [newProductData, setNewProductData] = useState([]);
  const [filterValue, setFilterValue] = useState({});
  const [filterMenuDetails, setFilterMenuDetails] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsLoader(true);
    dispatch(fetchProducts(offSet));
  }, [offSet]);

  useEffect(() => {
    if (productDetails && productDetails.products) {
      if (productDetails.products.length > 0 && isSelected) {
        setSelectedProduct(productDetails.products[0]);
        setIsSelected(false);
      }
      setProductData((prev) => [...prev, ...productDetails.products]);
      setNewProductData(productDetails.products);
      setIsLoader(false);
    }
  }, [productDetails]);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setAppElement("#root");
    dispatch(fetchFilter(application));
    dispatch(currentFilter(application));
    setLoading(true);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        productDetails?.products &&
        filterDetails?.filter &&
        currentFilterDetails?.filter
      ) {
        setLoading(false);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [productDetails, filterDetails, currentFilterDetails]);

  useEffect(() => {
    if (filterDetails && filterDetails.filter) {
      setFilterMenuDetails(filterDetails.filter);

      if (currentFilterDetails && currentFilterDetails.filter) {
        const currentFilterData = currentFilterDetails.filter.reduce(
          (acc, item) => {
            return { ...acc, ...item };
          },
          {}
        );
        const output = findObject(
          filterDetails.filter.filter,
          currentFilterData
        );
        setFilterValue(output);
      }
    }
  }, [filterDetails, currentFilterDetails]);

  const handleSpySearch = () => {
    setIsOpen(true);
  };

  const handleChangeDropdown = (item, selectedItem) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: selectedItem
    }));
    const filterData = {
      application: application,
      type: item.type,
      attribute: selectedItem.value
    };
    dispatch(changeFilter(filterData));
  };

  useEffect(() => {
    if (changeFilterDetails && changeFilterDetails.message) {
      window.location.reload();
    }
  }, [changeFilterDetails]);

  const handleChangeMultiDropdown = (item, value) => {
    let filterData = {};

    setFilterValue((prev) => ({
      ...prev,
      [item.type]: value
    }));

    if (value.length === 0 || value.length < filterValue[item.type].length) {
      const removedValue = filterValue[item.type].filter(
        (x) => value.indexOf(x) < 0
      );
      filterData = {
        application: application,
        type: item.type,
        attribute: removedValue.map((x) => x.value)
      };
    } else {
      const latestItem = value[value.length - 1];
      filterData = {
        application: application,
        type: item.type,
        attribute: latestItem.value
      };
    }
    dispatch(changeFilter(filterData));
  };

  const handleEnterItem = (item, value) => {
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: [...prev[item.type], { value: value, label: value }]
    }));

    const filterData = {
      application: application,
      type: item.type,
      attribute: value
    };

    dispatch(changeFilter(filterData));
  };

  const handleChangeKeyword = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (!isNaN(value)) {
      setFilterValue((prev) => ({
        ...prev,
        [name]: { value: value }
      }));
    }
  };

  const handleBlur = (e, item) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === "") return;

    const num = parseFloat(value);
    let newValue = value;

    if (value && !isNaN(value)) {
      newValue = num.toFixed(2) + item.unit;

      setFilterValue((prev) => ({
        ...prev,
        [name]: { value: newValue }
      }));

      const filterData = {
        application: application,
        type: name,
        attribute: value
      };

      dispatch(changeFilter(filterData));
    }
  };

  const handleFocus = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (value === "") return;
    const newValue = parseFloat(value.replace(/%$/, ""));

    setFilterValue((prev) => ({
      ...prev,
      [name]: { value: newValue }
    }));
  };

  const renderDropdownContent = (item, i) => {
    if (item.filter_bar) {
      if (item.input_type === "dropdown") {
        if (item.multiple_selection) {
          return (
            <FiterMultiDropdown
              key={i}
              item={item}
              filterValue={filterValue}
              onChange={handleChangeMultiDropdown}
              minWidth={"110px"}
            />
          );
        } else {
          return (
            <FilterDropdown
              key={i}
              options={item.values.map((option) => ({
                label: option.value,
                value: option.request_value
              }))}
              placeholder={item.name}
              onChange={(value) => handleChangeDropdown(item, value)}
              value={filterValue[item.type]}
              width={"110px"}
            />
          );
        }
      } else {
        if (item.multiple_selection) {
          return (
            <FiterMultiKeyword
              key={i}
              item={item}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleEnterItem={handleEnterItem}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        } else {
          return (
            <FilterKeyword
              key={i}
              item={item}
              onChange={handleChangeKeyword}
              handleBlur={(e) => handleBlur(e, item)}
              handleFocus={handleFocus}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
            />
          );
        }
      }
    }
  };

  return (
    <Layout>
      <section className="home-section bg-background overflow-auto xl:overflow-hidden">
        <div className="text border-b-[1px] border-borderBottom">
          <SubHeader date>
            <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
              Spy
              <span className="text-primary ml-1 font-semibold tracking-wide">
                Search
              </span>
            </p>
          </SubHeader>
        </div>

        <div className="flex flex-wrap gap-6 pb-2 pt-2 px-4 md:px-10 product-drp-header">
          {filterMenuDetails["filter"] &&
            filterMenuDetails["filter"].map((item, index) =>
              renderDropdownContent(item, index)
            )}

          <div className="dropdown ">
            <button
              className="dropbtn hover:border-primary  bg-secondary  text-primary cursor-pointer text-sm border-[1px] border-primary font-medium flex pr-2 shadow  "
              onClick={handleSpySearch}
            >
              Hinzufügen &nbsp;
              <i className="bx bx-filter text-[16px] pt-1 text-[primary]"></i>
            </button>
          </div>
        </div>

        {productData.length > 0 ? (
          <div className="px-4 md:px-10">
            <section className="gap-4 grid grid-rows-1 xl:grid-cols-2 lg:grid-cols-1 mb-20">
              <SpyProductCard
                productData={productData}
                setSelectedProduct={setSelectedProduct}
                setOffSet={setOffSet}
                isLoader={isLoader}
                newProductData={newProductData}
              />
              <ProductRightCard selectedProduct={selectedProduct} />
            </section>
          </div>
        ) : (
          <NoSellersEmpty />
        )}

        {loading && (
          <div className="spinner">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          </div>
        )}
      </section>

      <CommonModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeModal={closeModal}
      >
        <SellerTracking closeModal={closeModal} />
      </CommonModal>
    </Layout>
  );
};

export default SpySearch;
