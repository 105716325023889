export const overViewMockData = [
  {
    title: "A2A Flips",
    icon: "static/amazon-flips-dark.svg",
    iconLight: "static/LightMode/Amazon-Light.svg",
    link: "a2aFlips"
  },
  {
    title: "Dealhub",
    icon: "static/dealHubDark.svg",
    iconLight: "static/dealHubLight.svg",
    link: "dealHub"
  },
  {
    title: "Preiswecker",
    icon: "static/Preiswecker-Dark.svg",
    iconLight: "static/LightMode/Preiswecker-Light.svg",
    link: "preiswecker"
  },
  {
    title: "Spy Search",
    icon: "static/Spy-Search-Dark.svg",
    iconLight: "static/LightMode/Spy-Search-Light.svg",
    link: "spySearch"
  },
];

export const selectCard = [
  {
    title: "Heutige Pings",
    link: "der letzten 24h",
    desc: 208
  }
];
