import Select, { components } from "react-select";
import { ThemeContext } from "../../context/themeContext";
import React, { useContext } from "react";

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {props.getValue().length > 0 ? (
        <>
          <div className="flex flex-wrap">
            {props
              .getValue()
              .map((option) => option.label)
              .join(", ")}
          </div>
          {children[1]}
        </>
      ) : (
        <components.ValueContainer {...props}>
          {children}
        </components.ValueContainer>
      )}
    </components.ValueContainer>
  );
};

const FiterMultiDropdown = ({ item, filterValue, onChange, minWidth }) => {
  const options = item.values.map((option) => ({
    label: option.value,
    value: option.request_value
  }));

  const value = filterValue && filterValue[item.type];
  const { theme } = useContext(ThemeContext);
  const customStyles = {
    control: (styles) => ({
      ...styles,
      minWidth,
      backgroundImage:
        theme === "dark"
          ? "linear-gradient(0deg, #141417 25%, rgba(20, 20, 23, 0.03) 75%)"
          : "0deg, #141417 0%, rgba(20, 20, 23, 0.0) 100%",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"
    })
  };

  return (
    <Select
      isMulti
      classNamePrefix="select"
      hideSelectedOptions={false}
      name={item.type}
      isClearable={false}
      options={options}
      onChange={(newValue) => onChange(item, newValue)}
      value={value || []}
      placeholder={item.name}
      components={{
        IndicatorSeparator: () => null,
        ValueContainer
      }}
      styles={customStyles}
      classNames={{
        control: () =>
          "!border-borderBottom text-[15px] text-center px-2 text-sm focus:outline-none rounded shadow-md !bg-text py-[1px]",
        valueContainer: () => "!text-textTitle",
        input: () => "!text-textTitle",
        menu: () => "!text-textTitle !bg-text",
        placeholder: () => "!text-textPrimary text-[12px]",
        dropdownIndicator: () => "w-[33px] !text-[#6E6F6F] mr-[-10px]",
        option: ({ isFocused, isSelected }) =>
          `hover:!bg-borderBottom ${
            isSelected
              ? "!bg-secondary !text-primary"
              : isFocused
              ? "!bg-transparent"
              : ""
          }`
      }}
    />
  );
};

export default FiterMultiDropdown;
