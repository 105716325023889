import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { priceAlertInfoAPI, deleteAllPriceAlertAPI } from "../../lib/api/api";

export const fetchPriceAlertInfo = createAsyncThunk(
  "priceAlertInfo/priceAlertInfoAPI",
  async (type, { dispatch }) => {
    const response = await priceAlertInfoAPI(type);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const deleteAllPriceAlert = createAsyncThunk(
  "deleteAllPriceAlert/deleteAllPriceAlertAPI",
  async (type, { dispatch }) => {
    const response = await deleteAllPriceAlertAPI(type);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const priceAlertInfoSlice = createSlice({
  name: "priceAlertInfo",
  initialState: {
    priceAlertInfo: null,
    status: "idle",
    error: null,
    deletePriceAlert: null,
    deletePriceStatus: "idle",
    deletePriceError: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPriceAlertInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPriceAlertInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.priceAlertInfo = action.payload;
      })
      .addCase(fetchPriceAlertInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteAllPriceAlert.pending, (state) => {
        state.deletePriceStatus = "loading";
      })
      .addCase(deleteAllPriceAlert.fulfilled, (state, action) => {
        state.deletePriceStatus = "succeeded";
        state.deletePriceAlert = action.payload;
      })
      .addCase(deleteAllPriceAlert.rejected, (state, action) => {
        state.deletePriceStatus = "failed";
        state.deletePriceError = action.error.message;
      });
  }
});
export const { setAuthStatus } = priceAlertInfoSlice.actions;
export default priceAlertInfoSlice.reducer;
