import React, { useEffect } from "react";
import ProductList from "../containers/ProductList";
import { useDispatch } from "react-redux";
import { resetA2AProducts } from "../redux/slices/a2aProductSlice";

const Preiswecker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetA2AProducts());
  }, []);

  return (
    <ProductList
      application="pricealerts"
      productType="pricealerts_products"
      header="Shop"
      subHeader="Preiswecker"
      filterSubHeader="Deine Filter für Preiswecker"
      noProductbtnTitle="Loslegen"
      noProductDesc="Du bist dir nicht sicher wie du neue Produkte hinzufügen kannst? Dann schau dir unseren ausführlichen Guide an"
      priceAlertInfoName="price_alert_info_pricealerts"
      addPriceAlertName="add_price_alert_pricealerts"
      deletePriceAlertName="delete_price_alert_pricealerts"
      asinsModalHeader="Preiswecker"
      asinsModalSubHeader="Lade eine CSV Datei mit Asins hoch um automatisch Preiswecker zu stellen. Noch nie gemacht?"
    />
  );
};

export default Preiswecker;
