export const productHeading = [
  { title: "margin", value: "margin" },
  { title: "profit", value: "profit" },
  { title: "buy", value: "buy_price" },
  { title: "sell", value: "sell_price" },
  { title: "seller", value: "seller_id" },
  { title: "margin 30d", value: "margin_30d" },
  { title: "profit 30d", value: "profit_30d" },
  { title: "bsr", value: "salesrank" }
];

export const tooltipData = [
  {
    text: "Lege deine Zielmarge für deine Produkte fest. (Berechnet anhand des aktuellen Preises)",
    type: "min_margin"
  },
  {
    text: "Setze einen Maximalwert für den BSR, um langsam verkaufende Produkte zu vermeiden.",
    type: "max_bsr"
  },
  {
    text: "Setze deine Zielmarge anhand des 30-Tage-Durchschnittspreises fest.",
    type: "min_margin_avg30"
  },
  {
    text: "Wähle zwischen A2A und dem Preiswecker-Modus aus, welche der Ergebnisse du erhalten möchtest.",
    type: "product_type"
  },
  {
    text: "Setze Keywords auf die Blacklist, somit werden dir diese nicht mehr für den Verkauf vorgeschlagen.",
    type: "keyword_blacklist"
  },
  {
    text: "Lege einen Mindestpreis fest. Alle Produkte, die unter diesem Wert liegen, werden dir nicht angezeigt.",
    type: "min_price"
  },
  {
    text: "Wähle, inwieweit du zurück in die Vergangenheit Produkte gezeigt bekommen möchtest.",
    type: "time"
  },
  {
    text: "Lege einen Maximalpreis fest. Alle Produkte, die über diesem Wert liegen, werden dir nicht angezeigt.",
    type: "max_price"
  },
  {
    text: "Setze Länder auf die Blacklist, somit werden dir diese nicht mehr für den Verkauf vorgeschlagen.",
    type: "country"
  }
];