import React from "react";

const SubHeader = ({ children, date }) => {
  const currentDate = new Date().toLocaleDateString("de-DE", {
    day: "numeric",
    month: "long",
    year: "numeric"
  });

  return (
    <>
      {children}
      {date && <p className="subtitle2 ">Deine Übersicht für den {currentDate}</p>}
    </>
  );
};

export default SubHeader;
