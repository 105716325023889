import React, { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import { Link } from "react-router-dom";

const Header = () => {
  const { toggleTheme, theme } = useContext(ThemeContext);
  return (
    <div className="sticky top-0 z-20">
      <div className="topbar text-primary bg-bgColor border-b-[1px] border-borderBottom">
        <div className="logo ">
          <Link to="/">
            <img
              src={
                theme === "dark"
                  ? "static/logo-dark.svg"
                  : "static/logo-light.svg"
              }
              className="edgelogo"
              alt="logo1"
            />
          </Link>
        </div>
        <div className="flex justify-between items-center gap-5">
          <div
            className="bg-secondary rounded-md p-[4px] flex items-center justify-center  cursor-pointer"
            onClick={toggleTheme}
          >
            <img
              src={
                theme === "dark"
                  ? "static/Light-Mode.svg"
                  : "static/dark-mode.svg"
              }
              width={21}
              height={21}
              alt="modeIcon"
            />
          </div>
          <div className="bg-secondary p-2 rounded-md shadow shadow-black/10">
            <p className=" flex justify-between items-center tracking-wide font-normal">
              <span className="mr-3 text-sm">
                <img
                  src={
                    theme === "dark"
                      ? "static/puzzle.svg"
                      : "static/puzzle2.svg"
                  }
                  width={15}
                  height={15}
                  alt="puzzle"
                />
              </span>
              <span className="text-xs hidden sm:block">
              NEU: Willkommen in unserem neuen Dashboard !
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
