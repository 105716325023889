import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductDetails } from "../redux/slices/productDetailsSlice";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import { ThemeContext } from "../context/themeContext";
import SpySkeleton from "./SpySkeleton";

const ProductRightCard = ({ selectedProduct }) => {
  const { theme } = useContext(ThemeContext);
  const imgRef = useRef(null);

  const dispatch = useDispatch();
  const selectedProductDetails = useSelector(
    (state) => state.productDetails.productDetails
  );
  const authStatus = useSelector((state) => state.productDetails.authStatus);
  useCheckUserAuth(authStatus);

  const [selectedProductData, setSelectedProductData] = useState({});
  const [storeSearchData, setStoreSearchData] = useState([]);
  const [storeSalesData, setStoreSalesData] = useState([]);
  const [informationData, setInformationData] = useState([]);
  const [topbarData, setTopbarData] = useState([]);
  const [isChartLoader, setIsChartLoader] = useState(false);
  const [chartImage, setChartImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0) {
      const asin = selectedProduct.asin;
      dispatch(fetchProductDetails(asin));
      setLoading(true);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProductDetails && selectedProductDetails.data) {
      setSelectedProductData(selectedProductDetails.data);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [selectedProductDetails]);

  const convertToDomain = (url) => {
    const domain = new URL(url).hostname;
    const mainDomain = domain.split(".").slice(-2).join(".");
    return mainDomain;
  };

  useEffect(() => {
    let storeData = [];

    if (selectedProductData.top_bar) {
      setTopbarData(selectedProductData.top_bar);
    }

    if (selectedProductData.store_search) {
      selectedProductData.store_search.forEach((store) => {
        storeData.push({
          title: convertToDomain(store.store_search),
          value: store.store_search_price,
          link: store.store_search
        });
      });
      setStoreSearchData(storeData);
    }

    if (selectedProductData.sales_data) {
      setStoreSalesData(selectedProductData.sales_data);
    }

    if (selectedProductData.information) {
      setInformationData(selectedProductData.information);
    }

    if (selectedProductData.graph_img) {
      if (theme === "dark") {
        if (selectedProductData.graph_img.dark) {
          setChartImage(selectedProductData.graph_img.dark);
        } else {
          setChartImage("static/NotAvailableChart-Dark.png");
        }
      } else {
        if (selectedProductData.graph_img.white) {
          setChartImage(selectedProductData.graph_img.white);
        } else {
          setChartImage("static/NotAvailableChart-White.png");
        }
      }
    }
  }, [selectedProductData, theme]);

  useEffect(() => {
    if (chartImage) {
      setIsChartLoader(true);
    }
  }, [chartImage]);

  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      setIsChartLoader(false);
    }
  }, [imgRef.current]);

  return (
    <div className="flex flex-col text-[13px] md:w-auto">
      {loading ? (
        <SpySkeleton />
      ) : (
        <div className="rounded-2xl p-2 border-[1px] !border-borderBottom shadow overflow-auto scrollHide xl:h-[300px]">
          <div className="p-2">
            <div className="text-cardTitle list-none">
              <p className="text-[18px] font-[400] truncate" id="title">
                {selectedProduct.name}
              </p>
            </div>
          </div>
          <div className="left-div pb-0 ">
            <div className="flex gap-5 pb-2 px-4 flex-wrap ">
              {topbarData.map((item, i) => {
                return (
                  <div key={`${item.name}-${i}`} className="text-left">
                    <p className="subtitle !text-[11px] ">{item.name}</p>
                    <div className=" rounded border-[1px] border-borderBottom shadow">
                      <p className="p_detail px-8 pt-1 pb-1  !text-[12px] text-textTitle ">
                        {item.value}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="px-4">
              <div className="grid grid-cols-3 max-sm:grid-cols-1 max-xl:grid-cols-3 max-md:grid-cols-3 max-2xl:grid-cols-3 gap-3">
                <div>
                  <div className="flex gap-2">
                    <p className="text-textPrimary text-[11px] pt-[4px] pb-[4px]">
                      STORE SEARCH
                    </p>
                    <img src="static/store-search.svg" width={15} alt="store" />
                  </div>
                  {storeSearchData.map((item, index) => {
                    return (
                      <div key={`${item.title}-${index}`} className="mb-3">
                        <a
                          className="pt-2 shrink-0"
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="text-left ">
                            <div className="rounded justify-between flex border-[1px] !border-borderBottom shadow items-center">
                              <div className="flex px-2">
                                <img
                                  src="static/Link-2.svg"
                                  width={15}
                                  alt="link"
                                />

                                <p className="p_detail px-2 pt-1 pb-1 text-textTitle truncate">
                                  {item.title}
                                </p>
                              </div>
                              <p className="p_detail px-2 pt-1 pb-1 rounded m-1 bg-primarybgColor !text-[12px] text-primarytextColor w-16">
                                {item.value}€
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>

                <div>
                  <div className="flex gap-2">
                    <p className="text-textPrimary text-[11px] pt-[4px] pb-[4px]">
                      PRODUCT DATA
                    </p>
                    <img
                      src="static/sales-data copy.svg"
                      width={10}
                      alt="sales"
                    />
                  </div>

                  {storeSalesData.map((item, i) => {
                    return (
                      <div key={`${item.name}-${i}`} className="mb-3">
                        <div className="text-left">
                          <div className=" rounded justify-between flex border-[1px] !border-borderBottom shadow items-center">
                            <span className="p_detail px-2 pt-1 pb-1 text-textPrimary truncate">
                              {item.name}
                            </span>
                            <span className="p_detail px-2 pt-1 pb-1 rounded m-1 bg-primarybgColor !text-[12px] text-primarytextColor w-16">
                              {item.value}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div>
                  <div>
                    <div className="flex gap-2">
                      <p className="text-textPrimary text-[11px] pt-[4px] pb-[4px]">
                        INFORMATION
                      </p>
                      <img src="static/check.svg" width={15} alt="check" />
                    </div>
                  </div>
                  {informationData.map((item, i) => {
                    const value =
                      typeof item.value === "number"
                        ? item.value
                        : item.value?.toString();

                    return (
                      <div key={`${item.name}-${i}`} className="mb-3">
                        <div className="text-left">
                          <div className=" rounded  justify-between flex border-[1px] !border-borderBottom shadow items-center">
                            <span className="p_detail px-2 pt-1 pb-1 text-textPrimary truncate">
                              {item.name}
                            </span>
                            <span className="p_detail px-2 pt-1 pb-1 rounded m-1 !text-[12px] text-textTitle w-16">
                              {value}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex-1 rounded-2xl p-2 mt-6 border-[1px] !border-borderBottom shadow my-3 flex justify-center items-center chartHeight">
        {isChartLoader ? (
          <div className="flex w-full h-full items-center justify-center">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary "></i>
          </div>
        ) : null}

        <img
          ref={imgRef}
          src={chartImage}
          onLoad={() => setIsChartLoader(false)}
          onError={() => setIsChartLoader(false)}
          alt="graph_image"
          className={`w-full h-full p-6 chart-padding object-contain ${
            isChartLoader ? "hidden" : ""
          }`}
        />
      </div>
    </div>
  );
};
export default ProductRightCard;
