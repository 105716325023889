import React, { useEffect } from "react";
import ProductList from "../containers/ProductList";
import { useDispatch } from "react-redux";
import { resetA2AProducts } from "../redux/slices/a2aProductSlice";

const AmazonFlips = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetA2AProducts());
  }, []);

  return (
    <ProductList
      application="a2a"
      productType="a2a_products"
      header="A2A"
      subHeader="Flips"
      filterSubHeader="Deine Filter für A2A Flips"
      noProductbtnTitle="Neu laden"
      noProductDesc="Wir konnten keine Produkte finden, möglicherweise liegt dies an deinen Filtern. Wähle alle Filter ab oder lade diese Seite neu!"
      priceAlertInfoName="price_alert_info_a2a"
      addPriceAlertName="add_price_alert_a2a"
      deletePriceAlertName="delete_price_alert_a2a"
      asinsModalHeader="Asins"
      asinsModalSubHeader="Füge neue Asins hinzu, bearbeite diese oder lösche bestehende
      Asins."
    />
  );
};

export default AmazonFlips;
