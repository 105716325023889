import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "../context/themeContext";

const Toast = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme={theme}
      style={{ zIndex: 99999 }}
    />
  );
};

export default Toast;
