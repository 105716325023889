import { combineReducers, configureStore } from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import authCodeReducer from './slices/authSlice';
import logoutReducer from './slices/logoutSlice';
import overviewReducer from './slices/overviewSlice';
import productsReducer from './slices/productsSlice';
import productDetailsReducer from './slices/productDetailsSlice';
import sellerTrackingReducer from './slices/sellerTrackingSlice';
import filterReducer from './slices/filterSlice';
import a2aProductReducer from './slices/a2aProductSlice';
import addPriceAlertReducer from './slices/addPriceAlertSlice';
import priceAlertInfoReducer from './slices/priceAlertInfoSlice';


const rootReducer = combineReducers({
    login: loginReducer,
    auth: authCodeReducer,
    logout: logoutReducer,
    overview: overviewReducer,
    products: productsReducer,
    productDetails: productDetailsReducer,
    sellerTracking: sellerTrackingReducer,
    filter: filterReducer,
    a2aProducts : a2aProductReducer,
    addPriceAlert: addPriceAlertReducer,
    priceAlertInfo: priceAlertInfoReducer,
})

const store = configureStore({
    reducer: rootReducer
});

export default store;