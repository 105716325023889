import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSpyProductDetailsAPI } from "../../lib/api/api";

export const fetchProductDetails = createAsyncThunk(
  "productDetails/getSpyProductDetailsAPI",
  async (asin, { dispatch }) => {
    const response = await getSpyProductDetailsAPI(asin);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState: {
    productDetails: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productDetails = action.payload;
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});
export const { setAuthStatus } = productDetailsSlice.actions;
export default productDetailsSlice.reducer;
