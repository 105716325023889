import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoginRedirectAPI } from "../../lib/api/api";

const initialState = {
  data: null,
  loading: false
};

export const fetchLoginUrl = createAsyncThunk(
  "data/fetchLoginUrl",
  async () => {
    const res = await getLoginRedirectAPI();
    return res;
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginUrl.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLoginUrl.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchLoginUrl.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default loginSlice.reducer;
