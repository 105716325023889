import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addSellerTrackingAPI,
  deleteSellerTrackingAPI,
  getSellerTrackingAPI
} from "../../lib/api/api";
import { toast } from "react-toastify";

export const fetchSellerTracking = createAsyncThunk(
  "seller/getSellerTrackingAPI",
  async (_, { dispatch }) => {
    const response = await getSellerTrackingAPI();
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }

    return response;
  }
);

export const addSellerTracking = createAsyncThunk(
  "addSeller/addSellerTrackingAPI",
  async (sellerTracking, { dispatch }) => {
    const { sellername, sellerid } = sellerTracking;

    const response = await addSellerTrackingAPI(sellername, sellerid);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    if (response?.message === "success") {
      toast.success("Seller tracking added successfully");
      dispatch(fetchSellerTracking());
    }
    return response;
  }
);

export const deleteSellerTracking = createAsyncThunk(
  "seller/deleteSellerTrackingAPI",
  async (sellerid, { dispatch }) => {
    const response = await deleteSellerTrackingAPI(sellerid);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }

    if (response?.message === "success") {
      toast.success("Seller tracking deleted successfully");
      dispatch(fetchSellerTracking());
    }
    return response;
  }
);

const sellerTrackingSlice = createSlice({
  name: "seller",
  initialState: {
    seller: null,
    status: "idle",
    error: null,
    addSeller: null,
    addStatus: "idle",
    addError: null,
    deleteSeller: null,
    deleteStatus: "idle",
    deleteError: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellerTracking.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSellerTracking.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.seller = action.payload;
      })
      .addCase(fetchSellerTracking.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addSellerTracking.pending, (state) => {
        state.addStatus = "loading";
      })
      .addCase(addSellerTracking.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.addSeller = action.payload;
      })
      .addCase(addSellerTracking.rejected, (state, action) => {
        state.addStatus = "failed";
        state.addError = action.error.message;
      })
      .addCase(deleteSellerTracking.pending, (state) => {
        state.deleteStatus = "loading";
      })
      .addCase(deleteSellerTracking.fulfilled, (state, action) => {
        state.deleteStatus = "succeeded";
        state.deleteSeller = action.payload;
      })
      .addCase(deleteSellerTracking.rejected, (state, action) => {
        state.deleteStatus = "failed";
        state.deleteError = action.error.message;
      });
  }
});

export const { setAuthStatus } = sellerTrackingSlice.actions;
export default sellerTrackingSlice.reducer;
