import React from "react";
const GuideCard = () => {
  const Mockdata = [
    {
      title: "Wie du Edge optimal nutzt"
    },
    {
      title: "Preiswecker: Tutorial"
    },
    {
      title: "Spy Search: Tutorial"
    },
    {
      title: "Einrichtung & Filter"
    },
    {
      title: "Deal Hunter: Tutorial"
    },
    {
      title: "A2A Flips: Tutorial"
    }
  ];

  return (
    <>
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 xl:col-span-8">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 mx-10 pt-8 gap-14">
            {Mockdata.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="bg-bgColor border-[1px] border-borderBottom shadow text-center rounded-2xl px-4 py-6"
                  >
                    <div className="flex flex-col gap-4">
                      <p className="text-textTitle text-[20px] font-semibold tracking-wide">
                        {item.title}
                      </p>
                      <div className="flex justify-center items-center pb-4">
                        <img
                          src="static/guide.svg"
                          className="w-[300px]"
                          alt="Guide-card"
                        />
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="col-span-12 xl:col-span-4">
          <div className="flex flex-wrap gap-16 flex-col pt-8">
            <div>
              <div className="bg-bgColor border-[1px] border-borderBottom h-[300px] shadow text-center rounded-2xl mr-10"></div>
            </div>
            <div className="flex flex-wrap flex-col gap-10 justify-center">
              <div className="bg-bgColor border-[1px] border-borderBottom h-[70px] shadow text-center rounded-2xl mr-10"></div>
              <div className="bg-bgColor border-[1px] border-borderBottom h-[70px] shadow text-center rounded-2xl mr-10"></div>
              <div className="bg-bgColor border-[1px] border-borderBottom h-[70px] shadow text-center rounded-2xl mr-10"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GuideCard;
