import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addPriceAlertAPI } from "../../lib/api/api";

export const addPriceAlert = createAsyncThunk(
  "addPriceAlert/addPriceAlertAPI",
  async (payload, { dispatch }) => {
    const { type, file } = payload;

    const response = await addPriceAlertAPI(type, file);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const addPriceAlertSlice = createSlice({
  name: "addPriceAlert",
  initialState: {
    addPriceAlert: null,
    status: "idle",
    error: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPriceAlert.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPriceAlert.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addPriceAlert = action.payload;
      })
      .addCase(addPriceAlert.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});
export const { setAuthStatus } = addPriceAlertSlice.actions;
export default addPriceAlertSlice.reducer;
