import React, { useContext } from "react";
import { ThemeContext } from "../../context/themeContext";

const FilterKeyword = ({
  item,
  onChange,
  handleBlur,
  handleFocus,
  filterValue
}) => {
  const value = filterValue[item.type].value;
  const { theme } = useContext(ThemeContext);

  return (
    <input
      type="text"
      className="border-borderBottom w-60 h-10 shadow-md text-center text-sm px-2 focus:outline-none rounded p-4 shadow-[0px 0px 5px 0px rgba(0, 0, 0, 0.1)] placeholder:text-[12px] placeholder:!text-textPrimary bg-text text-textTitle"
      name={item.type}
      onChange={onChange}
      placeholder={item.name}
      value={value || ""}
      onBlur={handleBlur}
      onFocus={handleFocus}
      style={{
        backgroundImage:
          theme === "dark"
            ? "linear-gradient(357deg, rgb(61 61 69) -135.67%, rgb(8 7 7) 68.75%) "
            : "linear-gradient(0deg, rgb(255 255 255 / 5%) -135.67%, rgb(255 255 255) 68.75%) ",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"
      }}
    />
  );
};

export default FilterKeyword;
