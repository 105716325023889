import React from "react";

const SpySkeleton = () => {
  return (
    <div className="rounded-2xl border-[1px] !border-borderBottom shadow overflow-auto scrollHide xl:h-[300px] p-4">
      <div className="mx-4 shadow bg-text rounded dark:bg-gradient-to-b from-bgColor to-[#141417] border-[1px] !border-borderBottom w-[600px] animate-pulse">
        <p className="p-2.5"></p>
      </div>
      <div className="flex gap-5 pb-2 px-4 flex-wrap my-6">
        {[0, 1, 2, 3].map((i) => (
          <div key={i} className="text-left">
            <div className="bg-text shadow p-2 border-[1px] !border-borderBottom rounded dark:bg-gradient-to-b from-bgColor to-[#141417] animate-pulse"></div>
            <div className="rounded border-[1px] border-borderBottom shadow bg-text mt-2 p-2 dark:bg-gradient-to-b from-bgColor to-[#141417] animate-pulse">
              <p className="px-8 py-1"></p>
            </div>
          </div>
        ))}
      </div>

      <div className="px-4 mt-4">
        <div className="grid grid-cols-3 max-sm:grid-cols-1 max-xl:grid-cols-3 max-md:grid-cols-3 max-2xl:grid-cols-3 gap-3">
          {[0, 1, 2].map((index) => (
            <div key={index}>
              <div className="flex gap-2 rounded shadow animate-pulse  bg-text border-[1px] !border-borderBottom mb-3 p-2 dark:bg-gradient-to-b from-bgColor to-[#141417]"></div>
              {[0, 1, 2].map((i) => (
                <div key={i} className="mb-3 shadow bg-text animate-pulse ">
                  <div className="rounded justify-between flex border-[1px] !border-borderBottom shadow items-center bg-text p-3 dark:bg-gradient-to-b from-bgColor to-[#141417]"></div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SpySkeleton;
