import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getOverviewAPI } from "../../lib/api/api";

export const fetchOverview = createAsyncThunk(
  "overview/getOverviewAPI",
  async () => {
    const response = await getOverviewAPI();
    return response;
  }
);

const overviewSlice = createSlice({
  name: "overview",
  initialState: {
    overview: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOverview.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.overview = action.payload;
      })
      .addCase(fetchOverview.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});

export default overviewSlice.reducer;
