import React, { useEffect } from "react";
import SubHeader from "../components/SubHeader";
import { ThemeContext } from "../context/themeContext";
import { useContext, useState } from "react";
import CommonModal from "../components/CommonModal";
import Tooltip from "../components/Tooltip";
import DeletingConfirmation from "./DeletingConfirmation";
import FileUpload from "./FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllPriceAlert,
  fetchPriceAlertInfo
} from "../redux/slices/priceAlertInfoSlice";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import { addPriceAlert } from "../redux/slices/addPriceAlertSlice";

const AsinsModal = ({
  closeModal,
  priceAlertInfoName,
  addPriceAlertName,
  deletePriceAlertName,
  asinsModalHeader,
  asinsModalSubHeader
}) => {
  const dispatch = useDispatch();

  const priceAlertData = useSelector(
    (state) => state.priceAlertInfo.priceAlertInfo
  );
  const addPriceAlertData = useSelector(
    (state) => state.addPriceAlert.addPriceAlert
  );
  const deletePriceAlertData = useSelector(
    (state) => state.priceAlertInfo.deletePriceAlert
  );

  const authStatus = useSelector((state) => state.priceAlertInfo.authStatus);
  useCheckUserAuth(authStatus);

  const { theme } = useContext(ThemeContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [priceAlertInfo, setPriceAlertInfo] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const customStyles = {
    background:
      theme === "dark"
        ? "linear - gradient(0deg, rgba(20, 20, 23, 0.18) - 135.67 %, rgba(255, 255, 255, 0) 68.75 %)"
        : "linear-gradient(0deg, rgba(20, 20, 23, 0.05) -135.67%, rgba(255, 255, 255, 0) 68.75%)",
    boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"
  };

  useEffect(() => {
    dispatch(fetchPriceAlertInfo(priceAlertInfoName));
    setIsLoader(true);
  }, []);

  useEffect(() => {
    if (
      priceAlertData?.message === "success" ||
      deletePriceAlertData?.message === "success"
    ) {
      setPriceAlertInfo(priceAlertData);
      setIsLoader(false);
    }
  }, [priceAlertData || deletePriceAlertData]);

  useEffect(() => {
    if (addPriceAlertData === undefined) {
      setIsLoader(false);
    }

    if (addPriceAlertData?.message === "success") {
      setPriceAlertInfo(addPriceAlertData);
      setIsLoader(false);
    }
  }, [addPriceAlertData]);

  const handleDeleteOpenModal = () => {
    setDeleteModal(true);
  };

  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleSave = () => {
    setIsLoader(true);

    const file = uploadedFiles[0];

    if (!file) {
      setErrorMessage("Bitte laden Sie eine Datei hoch.");
      setIsLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    const payload = { type: addPriceAlertName, file: formData };

    dispatch(addPriceAlert(payload));
  };

  const handleDelete = () => {
    setIsLoader(true);
    setDeleteModal(false);
    dispatch(deleteAllPriceAlert(deletePriceAlertName));
  };

  useEffect(() => {
    if (deletePriceAlertData === undefined) {
      setIsLoader(false);
    }

    if (deletePriceAlertData?.message === "success") {
      setPriceAlertInfo(deletePriceAlertData);
      setIsLoader(false);
    }
  }, [deletePriceAlertData]);

  return (
    <>
      <div className="relative">
        <div className="bg-background p-12 rounded-lg w-[400px] md:w-[700px] lg:w-[800px] overflow-auto h-[700px] md:h-auto">
          <div className="bg-background mb-4 flex justify-between items-center">
            <div>
              <SubHeader>
                <p className="title text-primary text-3xl font-medium">
                  {asinsModalHeader}
                  <span className="text-textTitle ml-1">bearbeiten</span>
                </p>
                <p className="subtitle2">{asinsModalSubHeader}</p>
              </SubHeader>
            </div>

            <div
              onClick={closeModal}
              className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded"
            >
              <img
                src={
                  theme === "dark"
                    ? "static/close-1.svg"
                    : "static/LightMode/close-2.svg"
                }
                width={15}
                className="float-right"
                alt="close"
              />
            </div>
          </div>
          <div className="flex gap-y-1 flex-col justify-center">
            <div className="grid w-fit gap-x-10 gap-y-4 md:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1">
              <div className="text-left gap-6">
                <div className="flex items-center">
                  <div>
                    <p className="subtitle !text-[11px] ">
                      Anzahl Preiswecker aktiv
                    </p>
                  </div>
                  <i className="tooltip-on-hover bx bx-question-mark text-[12px] text-secondary bg-textsecondary mx-1 rounded-lg text-center"></i>
                  <Tooltip label={"Hier kannst du sehen wieviele Preiswecker aktuell aktiv sind"} />
                </div>
                <input
                  type="text"
                  className="border-borderBottom w-60 h-10 shadow-md dark:bg-gradient-to-b from-bgColor to-[#141417] text-sm px-2 focus:outline-none text-center text-textTitle rounded p-4 drop-shadow-md"
                  name={"anzahlPreisweckeraktiv"}
                  onChange={() => {}}
                  value={priceAlertInfo.tracked_asins}
                  style={customStyles}
                />
              </div>

              <div className="text-left gap-6">
                <div className="flex items-center">
                  <div>
                    <p className="subtitle !text-[11px] ">
                      Anzahl Preiswecker frei{" "}
                    </p>
                  </div>
                  <i className="tooltip-on-hover bx bx-question-mark text-[12px] text-secondary bg-textsecondary mx-1 rounded-lg text-center"></i>
                  <Tooltip label={"Hier kannst du sehen wieviele Preiswecker Slots du noch frei hast"} />
                </div>
                <input
                  type="text"
                  className="border-borderBottom w-60 h-10 shadow-md dark:bg-gradient-to-b from-bgColor to-[#141417] text-sm  px-2 focus:outline-none text-center text-textTitle rounded p-4 drop-shadow-md"
                  name={"anzahlPreisweckerfrei"}
                  onChange={() => {}}
                  value={priceAlertInfo.asins_left}
                  style={customStyles}
                />
              </div>
            </div>

            <FileUpload
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
            />
            <div className="flex gap-4 mt-6">
              <button
                className="p_detail px-2 pt-2 pb-2 text-xs font-semibold   text-text border-none capitalize bg-gradient-to-br from-primary to-main rounded text-center w-28 tracking-widest"
                onClick={handleSave}
              >
                speichern
              </button>

              <button
                className="p_detail px-2 pt-2 pb-2 text-xs font-semibold text-text border-none capitalize bg-gradient-to-br from-[#FF4545]  to-[#FE8585] rounded text-center w-28 tracking-widest"
                onClick={handleDeleteOpenModal}
              >
                Alle löschen
              </button>
            </div>
          </div>
        </div>

        {isLoader && (
          <div className="spinner">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          </div>
        )}
      </div>

      <CommonModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        closeModal={handleDeleteModal}
      >
        <DeletingConfirmation
          handleDeleteModal={handleDeleteModal}
          handleDelete={handleDelete}
        />
      </CommonModal>
    </>
  );
};

export default AsinsModal;
