import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLogoutRedirectAPI } from "../../lib/api/api";

export const fetchLogout = createAsyncThunk(
  "data/fetchLogoutUrl",
  async () => {
    const res = await getLogoutRedirectAPI();
    return res;
  }
);

const logoutSlice = createSlice({
  name: "logout",
  initialState: {
    logout: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchLogout.pending, (state) => {
      state.status = "loading";
    })
    .addCase(fetchLogout.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.logout = action.payload;
    })
    .addCase(fetchLogout.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  }
});

export default logoutSlice.reducer;
