import React, { useEffect } from "react";
import LoginComponent from "../components/Login";
import { useDispatch } from "react-redux";
import { fetchLoginUrl } from "../redux/slices/loginSlice";

const LoginPage = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchLoginUrl());
  }, []);

  return (
    <div>
      <LoginComponent />
    </div>
  );
};

export default LoginPage;
