import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { sidebarData } from "../lib/mock/sidebarMock";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogout } from "../redux/slices/logoutSlice";
import { ThemeContext } from "../context/themeContext";
import Tooltip from "../components/Tooltip";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);

  const logoutData = useSelector((state) => state.logout.logout);
  const homeDetails = useSelector((state) => state.auth.homeData);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    if (window.innerWidth > 768) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  const handleLogout = () => {
    dispatch(fetchLogout());
  };

  useEffect(() => {
    if (logoutData && logoutData?.status === "unauthorized") {
      navigate("/login");
    }
  }, [logoutData]);

  const findIcon = (item, isActive) => {
    if (isActive) {
      return theme === "dark" ? item.enableDarkIcon : item.enableLightIcon;
    } else {
      return theme === "dark" ? item.darkIcon : item.lightIcon;
    }
  };

  const iconClass = (item, isActive) => {
    if (isActive) {
      return "!bg-secondary";
    } else if (item.disable) {
      return "disabled-link";
    } else {
      return "";
    }
  };

  return (
    <div
      className={`sidebar bg-bgColor border-b-[1px] border-borderBottom ${
        isSidebarOpen && "open"
      }`}
    >
      <div className="logo-details">
        <div className="logo_name">Menü</div>
        <i className="bx bx-menu" id="btn" onClick={toggleSidebar}></i>
      </div>
      <ul className="nav-list ">
        {sidebarData.map((item, index) => {
          const isActive = location.pathname === item.link;
          const iconSrc = findIcon(item, isActive);

          return (
            <li
              key={index}
              className={`flex ${!isSidebarOpen ? "justify-center" : ""}`}
            >
              <Link
                to={item.url ? item.url : item.link}
                target={item.url ? "_blank" : ""}
                rel="noreferrer"
                className={`hover:bg-hoverbgColor p-2 flex items-center gap-4 tooltip-on-hover ${
                  !isSidebarOpen ? "!size-9" : ""
                }
                ${iconClass(item, isActive)}`}
              >
                <img
                  className="flex items-center justify-center !h-6"
                  src={iconSrc}
                  alt="icon"
                  width={item.width}
                />
                <span className="links_name !text-left">{item.title}</span>
              </Link>
              <span className="tooltip bg-bgTooltip text-textTooltip border border-borderTootip">
                {item.title}
              </span>
              <Tooltip label={item.title} />
            </li>
          );
        })}

        <li className="profile !bg-background flex items-center">
          <div className="profile-details">
            <img
              src={homeDetails?.avatar}
              alt="img"
              className="rounded-full w-[45px] h-[45px]"
            />
            <div className="text-[14px] ml-3 whitespace-nowrap">
              <div className="text-textTitle capitalize">
                {homeDetails?.name}
              </div>
              <div className="text-textTitle capitalize">
                {homeDetails?.membership}
              </div>
            </div>
          </div>
          <div className="cursor-pointer" onClick={handleLogout}>
            <i
              className="bx bx-log-out bg-bgColor border-r-[1px] border-borderBottom"
              id="log_out"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
