import { ThemeContext } from "../../context/themeContext";
import Select, { components } from "react-select";
import React, { useContext } from "react";

const FilterDropdown = ({
  options,
  placeholder,
  width,
  onChange,
  value,
  isMulti
}) => {
  const { theme } = useContext(ThemeContext);

  const Option = (props) => {
    const { isSelected } = props;
    return (
      <components.Option {...props}>
        {isSelected && (
          <i
            className="bx bxs-circle  text-[8px] mx-[8px]"
            style={{
              marginLeft: "5px"
            }}
          ></i>
        )}
        {props.children}
      </components.Option>
    );
  };

  const customStyles = {
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 100
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundImage:
        theme === "dark"
          ? "linear-gradient(0deg, #141417 25%, rgba(20, 20, 23, 0.03) 75%)"
          : "0deg, #141417 0%, rgba(20, 20, 23, 0.0) 100%",
      // boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.1)",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)",

      borderColor: isFocused
        ? theme === "dark"
          ? "#343639"
          : ""
        : theme === "dark"
        ? "#343639"
        : "",
      "&:hover": {
        borderColor: theme === "dark" ? "#343639" : ""
      },
      borderRadius: "0.375rem",
      padding: "6px 2px",
      outline: "none",
      width
    }),

    menu: (styles) => ({
      ...styles,
      backgroundColor: theme === "dark" ? "black" : "white",
      color: theme === "dark" ? "white" : "black",
      width: width ?? "100%",
      maxHeight: "none",
      overflow: "hidden",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)"
    }),

    menuList: (styles) => ({
      ...styles,
      maxHeight: "none",
      overflow: "hidden",
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)"
    }),

    singleValue: (styles) => ({
      ...styles,
      color: theme === "dark" ? "white" : "black",
      fontSize: "12px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }),

    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isSelected
        ? theme === "dark"
          ? "#09201C"
          : "#D7E5FF"
        : isFocused
        ? theme === "dark"
          ? "#202024"
          : "#F5F5F5"
        : "transparent",
      color: isSelected
        ? theme === "dark"
          ? "#58FFB9"
          : "#5793FF"
        : isFocused
        ? theme === "dark"
          ? "#6E6F6F"
          : "#6E6F6F"
        : "#808080",

      paddingRight: isSelected ? "31px" : "",

      "&:hover": {
        backgroundColor: theme === "dark" ? "#202024" : "#F5F5F5"
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      textAlign: "center",
      width: width,
      fontSize: "12px"
    }),

    input: (styles, { isFocused }) => ({
      ...styles,
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      outline: isFocused ? "none" : "none"
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: "0px",
      fontSize: "8px",
      color: theme === "dark" ? "#6E6F6F" : "#6E6F6F",
      width: "17px",
      marginRight: "4px"
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none"
    })
  };

  return (
    <Select
      isMulti={isMulti}
      options={options}
      placeholder={placeholder || "Select"}
      className="basic-single  cursor-pointer!"
      classNamePrefix="select"
      isSearchable={false}
      onChange={onChange}
      value={value || null}
      menuPlacement="auto"
      menuPortalTarget={document.body}
      styles={customStyles}
      components={{ Option }}
      classNames={{
        control: () =>
          "shadow-md !bg-text text-center",
        placeholder: () => "!text-textPrimary text-[12px]"
      }}
    />
  );
};
export default FilterDropdown;
