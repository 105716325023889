import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthCodeAPI, getHomeAPI, getCheckAuthAPI } from "../../lib/api/api";

const initialState = {
  loading: false,
  status: "loading",
  authData: null,
  homeData: null,
  homeStatus: "idle",
  homeError: null
};

export const fetchAuthCode = createAsyncThunk(
  "data/checkUserAuth",
  async (code) => {
    const res = await getAuthCodeAPI(code);
    return res;
  }
);

export const fetchHomeOverview = createAsyncThunk(
  "data/fetchHomeOverview",
  async () => {
    const res = await getHomeAPI();
    return res;
  }
);

export const fetchCheckAuthAPI = createAsyncThunk(
  "data/getCheckAuthAPI",
  async () => {
    const res = await getCheckAuthAPI();
    return res;
  }
);

const authSlice = createSlice({
  name: "authCode",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAuthCode.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload?.status;
      })
      .addCase(fetchAuthCode.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCheckAuthAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCheckAuthAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload?.status;
        state.authData = action.payload;
      })
      .addCase(fetchCheckAuthAPI.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchHomeOverview.pending, (state) => {
        state.homeStatus = "loading";
      })
      .addCase(fetchHomeOverview.fulfilled, (state, action) => {
        state.homeStatus = "succeeded";
        state.homeData = action.payload;
      })
      .addCase(fetchHomeOverview.rejected, (state, action) => {
        state.homeStatus = "failed";
        state.error = action.error.message;
      });
  }
});

export default authSlice.reducer;
