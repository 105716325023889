import React from "react";
import { ThemeContext } from "../context/themeContext";
import { useContext } from "react";

const NoSellersEmpty = () => {
  const { theme } = useContext(ThemeContext);

  const imageSrc =
    theme === "dark"
      ? "static/Darkmode/No-Sellers-Midpiece2.svg"
      : "static/LightMode/No-Sellers-Midpiece1.svg";

  const image2Src =
    theme === "dark" ? "static/no-product.png" : "static/no-product-light.png";

  return (
    <div className="mt-16 flex justify-center items-center flex-col gap-10 mb-10 sm:gap-20">
      <div className="flex justify-center items-center">
        <img src={imageSrc} alt="noSellersEmpty" className="w-full" />
      </div>
      <div>
        <p className="text-primary text-center sm:w-[340px] w-[220px] text-[16px]">
          Neu hier? Klicke auf den Hinzufügen Knopf oben in der Leiste um Seller
          zu verfolgen.
        </p>
        <div className="flex justify-center items-center pt-4">
          <img src={image2Src} alt="noProductData" className="w-full" />
        </div>
      </div>
    </div>
  );
};
export default NoSellersEmpty;
