import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSpyProductsAPI } from "../../lib/api/api";

export const fetchProducts = createAsyncThunk(
  "products/getSpyProductsAPI",
  async (offSet, { dispatch }) => {
    const response = await getSpyProductsAPI(offSet);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }

    return response;
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: null,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  }
});
export const { setAuthStatus } = productsSlice.actions;
export default productsSlice.reducer;
