import React from "react";

const Tooltip = ({ label }) => {
  return (
    <div className="tooltip">
      <div className="relative flex items-center group">
        <div className="absolute min-w-56 z-10 p-[10px] bg-text text-textTitle text-[12px] rounded shadow-lg">
          <p className="text-center">{label}</p>
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
