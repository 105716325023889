export const sidebarData = [
  {
    title: "Dashboard",
    darkIcon: "static/Darkmode/dashboard-icon-inactive1.svg",
    lightIcon: "static/LightMode/dashboard-icon-inactive2.svg",
    enableDarkIcon: "static/Darkmode/dashboard-icon-active1.svg",
    enableLightIcon: "static/LightMode/dashboard-icon-active2.svg",
    link: "/home",
    width: 20
  },
  {
    title: "Spy Search",
    darkIcon: "static/Darkmode/spy-icon-inactive1.svg",
    lightIcon: "static/LightMode/spy-icon-inactive2.svg",
    enableDarkIcon: "static/Darkmode/spy-icon-active1.svg",
    enableLightIcon: "static/LightMode/spy-icon-active2.svg",
    link: "/spySearch",
    width: 20
  },
  {
    title: "A2A Flips",
    darkIcon: "static/Darkmode/amazon-inactive1.svg",
    lightIcon: "static/LightMode/amazon-inactive2.svg",
    enableDarkIcon: "static/Darkmode/amazon-active1.svg",
    enableLightIcon: "static/LightMode/amazon-active2.svg",
    link: "/a2aFlips",
    width: 20
  },
  {
    title: "Preiswecker",
    darkIcon: "static/Darkmode/preiswecker-inactive1.svg",
    lightIcon: "static/LightMode/preiswecker-inactive2.svg",
    enableDarkIcon: "static/Darkmode/preiswecker-active1.svg",
    enableLightIcon: "static/LightMode/preiswecker-active2.svg",
    link: "/preiswecker",
    width: 20
  },
  {
    title: "Dealhub",
    darkIcon: "static/Darkmode/dealhub-inactive1.svg",
    lightIcon: "static/LightMode/dealhub-inactive2.svg",
    enableDarkIcon: "static/Darkmode/dealhub-active1.svg",
    enableLightIcon: "static/LightMode/dealhub-active2.svg",
    link: "/dealHub",
    width: 20
  },
  {
    title: "Guide",
    darkIcon: "static/Darkmode/academy-inactive1.svg",
    lightIcon: "static/LightMode/academy-inactive2.svg",
    enableDarkIcon: "static/Darkmode/academy-active1.svg",
    enableLightIcon: "static/LightMode/academy-active2.svg",
    url: 'https://www.loom.com/share/folder/7cd44d80ead1474d8b6a88f76e2189f7',
    width: 20
  }
];
