import React, { useState, useContext } from "react";
import CommonModal from "../../components/CommonModal";
import { ThemeContext } from "../../context/themeContext";
import CreatableSelect from "react-select/creatable";

const FiterMultiKeyword = ({
  item,
  setInputValue,
  inputValue,
  handleEnterItem,
  filterValue,
  setFilterValue,
  onChange
}) => {
  const [open, setOpen] = useState(false);

  const { theme } = useContext(ThemeContext);

  const value = filterValue[item.type];

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundImage:
        theme === "dark"
          ? "linear-gradient(0deg, #141417 25%, rgba(20, 20, 23, 0.03) 75%)"
          : "0deg, #141417 0%, rgba(20, 20, 23, 0.0) 100%",
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)"
    })
  };

  const handleRemoveValue = (option) => {
    const newValue = value.filter((item) => item.value !== option.value);
    setFilterValue((prev) => ({
      ...prev,
      [item.type]: newValue
    }));
  };

  const renderSelectedOption = (option) => {
    const colourStyles = {
      multiValue: (styles) => {
        return {
          ...styles,
          backgroundColor: theme === "dark" ? "#58FFB9" : "#5793FF"
        };
      },
      multiValueLabel: (styles) => {
        return {
          ...styles,
          color: theme === "dark" ? "#000000" : "#FFFFFF",
          fontSize: "14px"
        };
      },
      multiValueRemove: (styles) => {
        return {
          ...styles,
          color: theme === "dark" ? "#000000" : "#FFFFFF",
          ":hover": {
            backgroundColor: "",
            color: "white"
          }
        };
      }
    };

    return (
      <div
        key={option.value}
        style={{ display: "flex", alignItems: "center", margin: "8px" }}
      >
        <div
          style={{
            ...colourStyles.multiValue({}),
            display: "flex",
            alignItems: "center",
            padding: "4px 8px",
            borderRadius: "4px"
          }}
        >
          <span style={colourStyles.multiValueLabel({})}>{option.label}</span>
          <span
            style={{
              marginLeft: "4px",
              cursor: "pointer",
              ...colourStyles.multiValueRemove({})
            }}
            onClick={() => handleRemoveValue(option)}
          >
            &times;
          </span>
        </div>
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        event.preventDefault();
        handleEnterItem(item, inputValue);

        break;

      default:
        break;
    }
  };

  const DropdownIndicator = () => {
    return (
      <>
        {value && value.length > 0 && (
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onTouchEnd={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <span onClick={() => setOpen(true)}>
              <i className="bx bx-expand !text-textTitle cursor-pointer"></i>
            </span>
          </div>
        )}
      </>
    );
  };

  const components = {
    DropdownIndicator,
    IndicatorSeparator: null
  };

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => onChange(item, newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={item.name}
        value={value || []}
        styles={customStyles}
        classNames={{
          control: () =>
            `!border-borderBottom text-center text-sm px-2 focus:outline-none rounded shadow-md !bg-text py-[1px]`,
          input: () => "!text-textTitle ",
          menu: () => "!text-textTitle !bg-text",
          placeholder: () => "!text-textPrimary text-[12px]",
          option: (isFocused, isSelected) => {
            return `${
              isSelected ? "!bg-primary" : isFocused ? "bg-transparent" : ""
            } hover:!bg-borderBottom`;
          },
          multiValue: () => "!bg-primary",
          multiValueLabel: () => "!text-text",
          multiValueRemove: () => "!text-text hover:!bg-primary"
        }}
      />

      <CommonModal
        isOpen={open}
        setIsOpen={setOpen}
        closeModal={() => setOpen(false)}
        height={200}
      >
        <div
          className="w-[200px] md:w-[400px] sm:w-[300px] overflow-auto"
          style={{ padding: "16px 10px" }}
        >
          <div
            onClick={() => setOpen(false)}
            className="cursor-pointer flex justify-end"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              alt="close"
            />
          </div>
          <div className="flex flex-wrap py-3">
            {value && value.length > 0 && value.map(renderSelectedOption)}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default FiterMultiKeyword;
