import axios from "axios";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true
});
const cookie = "sessionid=abcdef123456";

export const getHomeAPI = async () => {
  try {
    const res = await axiosInstance.get("/home", {
      headers: {
        Cookie: cookie
      }
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCheckAuthAPI = async () => {
  try {
    const res = await axiosInstance.get("/check_auth", {
      headers: {
        Cookie: cookie
      }
    });
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getLoginRedirectAPI = async () => {
  try {
    const res = await axiosInstance.get(
      "/login?redirect_uri=" + window.location.origin + "/oauth"
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getAuthCodeAPI = async (token) => {
  try {
    const res = await axiosInstance.get(
      "/oauth?code=" +
        token +
        "&redirect_uri=" +
        window.location.origin +
        "/oauth",
      {
        headers: {}
      }
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getLogoutRedirectAPI = async () => {
  try {
    const res = await axiosInstance.get("/logout");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getOverviewAPI = async () => {
  try {
    const res = await axiosInstance.get("/overviewData");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSpyProductsAPI = async (offSet) => {
  try {
    const res = await axiosInstance.get(`/spy_products?offset=${offSet}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSpyProductDetailsAPI = async (asin) => {
  try {
    const res = await axiosInstance.get(`/spy_detail?asin=${asin}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCurrentFilterAPI = async (application) => {
  try {
    const res = await axiosInstance.get(
      `/get_current_filter?application=${application}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const changeFilterAPI = async (application, type, attribute) => {
  try {
    const res = await axiosInstance.get(
      `/update_filter?application=${application}&type=${type}&attribute=${attribute}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getSellerTrackingAPI = async () => {
  try {
    const res = await axiosInstance.get("/tracked_seller");
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const addSellerTrackingAPI = async (sellername, sellerid) => {
  try {
    const res = await axiosInstance.get(
      `/add_seller?sellername=${sellername}&sellerid=${sellerid}`
    );
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSellerTrackingAPI = async (sellerid) => {
  try {
    const res = await axiosInstance.get(`/delete_seller?sellerid=${sellerid}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const getA2AProductsAPI = async (productType, offSet) => {
  try {
    const res = await axiosInstance.get(`/${productType}?offset=${offSet}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const priceAlertInfoAPI = async (type) => {
  try {
    const res = await axiosInstance.get(`${type}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const addPriceAlertAPI = async (type, file) => {
  try {
    const res = await axiosInstance.post(`/${type}`, file);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteAllPriceAlertAPI = async (type) => {
  try {
    const res = await axiosInstance.get(`/${type}`);
    return res.data;
  } catch (error) {
    toast.error(error.message);
  }
};
