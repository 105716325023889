import React, { useContext, useEffect, useState } from "react";
import SubHeader from "../components/SubHeader";
import { ThemeContext } from "../context/themeContext";
import { useDispatch, useSelector } from "react-redux";
import {
  addSellerTracking,
  deleteSellerTracking,
  fetchSellerTracking
} from "../redux/slices/sellerTrackingSlice";
import { useCheckUserAuth } from "../utlis/useCheckUserAuth";
import CommonModal from "../components/CommonModal";

const spySearchModal = [
  { title: "SELLER-ID", value: "seller_id" },
  { title: "LAND", value: "locale" },
  { title: "TRACKING SEIT", value: "tracked_since" },
  { title: "PINGS 24H", value: "pings24hrs" }
];

const SellerTracking = ({ closeModal }) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.sellerTracking.authStatus);
  useCheckUserAuth(authStatus);

  const sellerDetails = useSelector((state) => state.sellerTracking.seller);
  const [tutorialModal, setTutorialModal] = useState(false);
  const [sellerData, setSellerData] = useState([]);
  const [sellerName, setSellername] = useState("");
  const [sellerId, setSellerId] = useState("");
  const [error, setError] = useState("");
  const [issLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);
    dispatch(fetchSellerTracking());
  }, []);

  useEffect(() => {
    if (sellerDetails && sellerDetails.tracked_seller) {
      setSellerData(sellerDetails.tracked_seller);
      setIsLoader(false);
    }
  }, [sellerDetails]);

  const handleAddSellerTracking = () => {
    if (!sellerName || !sellerId) {
      setError("Both fields are required");
      return;
    }

    const sellerTracking = {
      sellername: sellerName,
      sellerid: sellerId
    };

    dispatch(addSellerTracking(sellerTracking));
    setSellername("");
    setSellerId("");
    setError("");
  };

  const handleSellerDelete = (seller) => {
    const sellerId = seller.seller_id;
    dispatch(deleteSellerTracking(sellerId));
  };
  const handlOpen = () => {
    setTutorialModal(true);
  };

  return (
    <div className="bg-background p-10 rounded-lg max-h-[700px] md:h-auto overflow-auto w-[360px] sm:w-[600px] md:w-[700px] lg:w-[800px]">
      <div className="bg-background mb-4 flex justify-between items-center">
        <div>
          <SubHeader>
            <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
              Seller
              <span className="text-primary ml-1 font-semibold tracking-wide">
                Tracking{" "}
              </span>
            </p>
            <p className="subtitle2">
              Füge Namen und die Seller-ID ein um einen Verkäufer anzulegen ・
              <span className="text-primary ml-1" onClick={handlOpen}>
                Tutorial
              </span>
            </p>
          </SubHeader>
        </div>

        <div
          onClick={closeModal}
          className="cursor-pointer bg-secondary w-[30px] h-[30px] flex justify-center items-center rounded"
        >
          <img
            src={
              theme === "dark"
                ? "static/close-1.svg"
                : "static/LightMode/close-2.svg"
            }
            width={15}
            className="float-right"
            alt="close"
          />
        </div>
      </div>

      <div className=" gap-4 pb-4 flex flex-wrap">
        <input
          type="text"
          className="w-[103px] placeholder:text-xs placeholder:text-center placeholder:text-textPrimary px-2 py-1 focus:outline-none border-[1px] border-borderBottom rounded shadow bg-text text-textTitle"
          placeholder="Nickname"
          value={sellerName}
          onChange={(e) => setSellername(e.target.value)}
        />
        <input
          type="text"
          className="w-[103px] placeholder:text-xs placeholder:text-center placeholder:text-textPrimary px-2 py-1 focus:outline-none border-[1px] border-borderBottom rounded shadow bg-text text-textTitle"
          placeholder="Seller-Id"
          value={sellerId}
          onChange={(e) => setSellerId(e.target.value)}
        />
        <button
          className="p-2 px-3 !text-[12px] font-semibold text-text tracking-wider	
                bg-gradient-to-br from-primary to-main rounded-md"
          onClick={handleAddSellerTracking}
        >
          Hinzufügen
        </button>
      </div>
      {error && <p className="text-red-500 text-xs mb-3">{error}</p>}

      {sellerData.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 max-h-[400px] overflow-y-auto no-scrollbar md:w-auto">
          {sellerData.map((seller, index) => {
            return (
              <div
                key={index}
                className="card p-3 rounded-xl border-[1px] border-borderBottom shadow "
              >
                <img
                  src={
                    theme === "dark"
                      ? "static/close-1.svg"
                      : "static/LightMode/close-2.svg"
                  }
                  width={10}
                  className="float-right cursor-pointer"
                  onClick={() => handleSellerDelete(seller)}
                  alt="close"
                />

                <div className="text-textTitle cursor-pointer list-none">
                  <p
                    className="text-[16px] font-medium text-primary"
                    id="title"
                  >
                    {seller.seller_name}
                  </p>
                </div>

                <div className="flex gap-3 flex-wrap">
                  {spySearchModal.map((item, index) => (
                    <div key={index} className="text-left">
                      <p className="subtitle !text-[11px] uppercase">
                        {item.title}
                      </p>
                      <div className="rounded w-full text-center border-[1px] border-borderBottom shadow bg-background">
                        <p className="p_detail pt-1 pb-1 px-3 !text-[12px] text-textTitle w-auto">
                          {seller[item.value]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex justify-center items-center my-3">
          {issLoader ? (
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          ) : sellerData.length === 0 ? (
            <p className="text-textTitle">Noch keine Verkäufer angelegt</p>
          ) : null}
        </div>
      )}
      <CommonModal
        isOpen={tutorialModal}
        setIsOpen={() => setTutorialModal(false)}
        closeModal={closeModal}
      >
        <div className="bg-background text-center p-10 rounded-lg max-h-[700px] md:h-auto overflow-auto w-[360px] sm:w-[600px] md:w-[700px] lg:w-[800px]">
          <div
            onClick={() => setTutorialModal(false)}
            className="cursor-pointer bg-secondary w-[30px] h-[30px] flex justify-center items-center float-end rounded"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              className="float-right"
              alt="close"
            />
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-textTitle text-2xl mb-3 font-bold	">
              Wie füge ich neue Seller hinzu?
            </h2>
            <p className="text-textTitle text-base	">
              Um neue Seller hinzufügen benötigst du die Seller-ID und einen
              Nickname.
            </p>
            <p className="text-textTitle text-base	">
              Die Seller-ID findest du wenn du die Storefront des Verkäufers
              öffnest hier im Link
            </p>
          </div>
          <div className="bg-subModalBg  py-4 px-3 text-[22px] rounded-full my-5 ">
            <p className="text-textTitle">
              amazone.de/s?me=
              <span className="uppercase">
                <span className="text-primary">sellerid</span>
                &marketplaceid=a1pa689
              </span>
            </p>
          </div>
          <div>
            <div className="flex flex-col gap-4">
              <p className="text-textTitle">
                Du findest Sie am Anfang des Link und sie sieht z.B so aus
              </p>
              <p className="uppercase text-textTitle">ALVXHKURZ8FRL</p>
              <p className="text-textTitle ">
                Kopiere diese und füge Sie unter der Spalte Seller-ID ein,
              </p>
              <p className="text-textTitle">
                füge einen Nickname hinzu und drücke auf hinzufügen.
              </p>
            </div>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default SellerTracking;
