import { createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthCode, fetchCheckAuthAPI } from "../redux/slices/authSlice";

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const authStatus = useSelector((state) => state.auth.status);
  const loading = useSelector((state) => state.auth.loading);
  const code = searchParams.get("code");
  const navigate = useNavigate();
  const location = useLocation();

  const callNavigation = (status) => {
    if (status === "authorized") {
      if (
        location.pathname === "/login" ||
        location.pathname === "/oauth" ||
        location.pathname === "/"
      ) {
        navigate("/home");
      } else {
        navigate(location.pathname, { replace: true });
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (authStatus) {
      if (code && !loading) {
        dispatch(fetchAuthCode(code))
          .unwrap()
          .then((res) => {
            callNavigation(res?.status);
          });
      } else if (!loading && authStatus !== "authorized") {
        dispatch(fetchCheckAuthAPI())
          .unwrap()
          .then((res) => {
            callNavigation(res?.status);
          });
      }
    }
  }, []);

  const value = {};

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
