import React, { useEffect } from "react";
import { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import SubHeader from "../components/SubHeader";
import Tooltip from "../components/Tooltip";
import FiterMultiDropdown from "./FilterMenu/FiterMultiDropdown";
import { currentFilter } from "../redux/slices/filterSlice";
import { useDispatch } from "react-redux";
import FilterDropdown from "./FilterMenu/FilterDropdown";
import FiterMultiKeyword from "./FilterMenu/FiterMultiKeyword";
import FilterKeyword from "./FilterMenu/FilterKeyword";
import { tooltipData } from "../lib/mock/productCardMock";

const FilterBearbeiten = ({
  closeModal,
  filterMenuDetails,
  handleChangeDropdown,
  filterValue,
  handleChangeKeyword,
  handleBlur,
  handleFocus,
  inputValue,
  setInputValue,
  handleChangeMultiDropdown,
  handleEnterItem,
  setFilterValue,
  isAsinsLoader,
  application,
  filterSubHeader,
  handleChangeMultiKeyword
}) => {
  const { theme } = useContext(ThemeContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(currentFilter(application));
  }, []);

  const tooltipText = (item) => {
    const text = tooltipData.find((data) => data.type === item)?.text;
    return text;
  };

  const renderDropdownContent = (item) => {
    if (item.input_type === "dropdown") {
      if (item.multiple_selection) {
        return (
          <FiterMultiDropdown
            item={item}
            filterValue={filterValue}
            onChange={handleChangeMultiDropdown}
          />
        );
      } else {
        return (
          <FilterDropdown
            options={item.values.map((option) => ({
              label: option.value,
              value: option.request_value
            }))}
            placeholder={item.name}
            onChange={(value) => handleChangeDropdown(item, value)}
            value={filterValue[item.type]}
          />
        );
      }
    } else {
      if (item.multiple_selection) {
        return (
          <FiterMultiKeyword
            item={item}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleEnterItem={handleEnterItem}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onChange={handleChangeMultiKeyword}
          />
        );
      } else {
        return (
          <FilterKeyword
            item={item}
            onChange={handleChangeKeyword}
            handleBlur={(e) => handleBlur(e, item)}
            handleFocus={handleFocus}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        );
      }
    }
  };

  const handleSave = () => {
    closeModal();
  };

  return (
    <>
      <div className="bg-background p-12 scrollHide rounded-lg w-[360px] sm:w-[600px] md:w-[700px] lg:w-[800px] overflow-auto h-[700px] md:h-auto">
        <div className="bg-background mb-4 flex justify-between items-center">
          <div>
            <SubHeader>
              <p className="title text-primary text-3xl font-medium">
                Filter
                <span className="text-textTitle ml-1"> bearbeiten</span>
              </p>
              <p className="subtitle2">{filterSubHeader}</p>
            </SubHeader>
          </div>
          <div
            onClick={closeModal}
            className="bg-secondary w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              className="float-right"
              alt="close"
            />
          </div>
        </div>
        <div className="flex justify-center w-full">
          <div className="grid w-fit gap-x-20 gap-y-6 md:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1">
            {filterMenuDetails &&
              filterMenuDetails.filter.map((item, index) => {
                return (
                  <div key={index} className="text-left w-60">
                    <div className="flex items-center">
                      <div>
                        <p className="subtitle !text-[12px] mr-1">
                          {item.name}
                        </p>
                      </div>
                      <i className="tooltip-on-hover bx bx-question-mark text-[12px] text-secondary bg-textsecondary mx-1 rounded-lg text-center"></i>
                      <Tooltip label={tooltipText(item.type)} />
                    </div>
                    {renderDropdownContent(item, index)}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex justify-center w-[200px] ml-[-20px] sm:ml-[90px] md:ml-[-15px] lg:ml-[35px] mt-4">
          <button
            className="p_detail text-xs font-semibold p-3 text-text bg-gradient-to-br from-primary to-main rounded text-center w-32 border-none capitalize mt-4"
            onClick={handleSave}
          >
            speichern
          </button>
        </div>
        {isAsinsLoader && (
          <div className="spinner">
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          </div>
        )}
      </div>
    </>
  );
};
export default FilterBearbeiten;
