import React, { useState } from "react";
import { convertToDomain, currencyFormatter, valueSign } from "../utlis/utlis";
import { productHeading } from "../lib/mock/productCardMock";

const SpyProductCard = ({
  productData,
  setSelectedProduct,
  setOffSet,
  isLoader,
  newProductData
}) => {
  const [copiedASIN, setCopiedASIN] = useState(null);

  const handleProduct = (item) => {
    setSelectedProduct(item);
  };

  const handleScroll = (e) => {
    const tolerance = 1;
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= tolerance;
    if (bottom && newProductData.length !== 0 && !isLoader) {
      setOffSet((prev) => prev + 10);
    }
  };

  const checkColor = (value) => {
    if (value >= 7) {
      return "!text-marginGreenText !bg-marginGreenBg";
    } else if (value <= 7 && value >= 3) {
      return "!text-marginOrangeText !bg-marginOrangeBg";
    } else if (!value) {
      return "text-textTitle bg-background border-[1px] border-borderBottom rounded shadow";
    } else {
      return "!text-marginRedText !bg-marginRedBg";
    }
  };

  const getColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const productClass = (heading, item) => {
    if (heading.title === "bsr") {
      return "cursor-pointer text-textTitle bg-background border-[1px] border-borderBottom rounded shadow";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getColorClass(item, heading)} rounded`;
    } else {
      return "text-textTitle bg-background border-[1px] border-borderBottom rounded shadow";
    }
  };

  const copyAsin = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedASIN(value);
      setTimeout(() => {
        setCopiedASIN(null);
      }, 500);
    });
  };

  const handleCopy = (item, heading) => {
    if (heading.title === "asin" || heading.title === "bsr") {
      copyAsin(item[heading.value]);
    }
  };

  const productContent = (item, heading) => {
    return (
      <div key={heading.title} className="text-left min-w-20">
        <div className="flex items-center">
          <p className="subtitle text-[11px] uppercase">{heading.title}</p>
          {heading.title === "bsr" && (
            <>
              {copiedASIN === item[heading.value] ? (
                <span className="ml-2 text-green-500 text-[10px] capitalize">
                  Kopiert!
                </span>
              ) : (
                <img
                  className="ml-2 cursor-pointer"
                  src={"/static/copy-icon.svg"}
                  alt="copy_icon"
                  width={8}
                  height={10}
                />
              )}
            </>
          )}
        </div>
        <div className="bg-primarybgColor rounded text-center shadow">
          <span
            className={`block  p_detail px-2 py-1 text-[12px] text-primarytextColor spy-product-box
            ${productClass(heading, item)}`}
            onClick={() => handleCopy(item, heading)}
          >
            {item[heading.value]
              ? `${currencyFormatter(item[heading.value])} ${
                  valueSign(heading.value) || ""
                }`
              : "N/A"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      onScroll={handleScroll}
      className="product-section text-[13px] md:w-auto card-bottom"
    >
      <div className="list gap-6">
        {productData?.map((item, i) => {
          const buyUrl = convertToDomain(item.buy_url);
          const sellUrl = convertToDomain(item.sell_url);

          return (
            <div key={`${item.name}-${i}`}>
              <div className="flex items-center rounded-2xl border-[1px] border-borderBottom p-3 shadow md:flex-row flex-col card-left-product">
                <div className="right-div pr-5 px-3">
                  <img
                    src={item.img}
                    alt="product_img"
                    className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl"
                  />
                </div>
                <div className="left-div">
                  <div
                    className="text-cardTitle list-none cursor-pointer capitalize flex flex-wrap justify-between items-center"
                    onClick={() => handleProduct(item)}
                  >
                    <p className="text-[16px] font-medium " id="name">
                      {item.name.length > 65
                        ? item.name.slice(0, 65) + "..."
                        : item.name}
                    </p>

                    <p className="text-[13px] text-textPrimary/50">
                      {item.timestamp}
                    </p>
                  </div>

                  <div className="flex gap-4 flex-wrap">
                    {productHeading
                      .slice(0, 5)
                      .map((heading) => productContent(item, heading))}
                  </div>

                  <div className="flex gap-4 mt-2 flex-wrap">
                    {productHeading
                      .slice(5, 8)
                      .map((heading) => productContent(item, heading))}

                    <div className="text-left">
                      <p className="subtitle text-[11px] uppercase">buy</p>
                      <div className="bg-gradient-to-br from-primary to-main rounded text-center">
                        <button
                          className="w-full truncate p_detail px-2 pt-1 pb-1 text-[12px] text-text border-none"
                          onClick={() => window.open(item.buy_url, "_blank")}
                        >
                          {buyUrl}
                        </button>
                      </div>
                    </div>
                    <div className="text-left">
                      <p className="subtitle text-[11px] uppercase">sell</p>
                      <div className="rounded bg-secondary border-[1px] border-primary text-center">
                        <button
                          className="w-full truncate file:p_detail px-2 pt-1 pb-1 text-[12px] text-primary"
                          onClick={() => window.open(item.sell_url, "_blank")}
                        >
                          {sellUrl}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex justify-center items-center my-3 mb-10 2xl:mb-10 xl:mb-15 lg:mb-20 ">
        {isLoader ? (
          <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
        ) : newProductData.length === 0 ? (
          <p className="text-textTitle">Keine weiteren Daten gefunden</p>
        ) : null}
      </div>
    </div>
  );
};
export default SpyProductCard;
