import React, { useState, useContext, useRef, useEffect } from "react";
import { convertToDomain, currencyFormatter, valueSign } from "../utlis/utlis";
import CommonModal from "../components/CommonModal";
import { ThemeContext } from "../context/themeContext";

const productHeading = [
  { title: "margin", value: "margin" },
  { title: "profit", value: "profit" },
  { title: "buy", value: "buy_price" },
  { title: "sell", value: "sell_price" },
  { title: "asin", value: "asin" },
  { title: "margin 30d", value: "margin_30d" },
  { title: "profit 30d", value: "profit_30d" },
  { title: "bsr", value: "salesrank" }
];

const ProductContent = ({
  productData,
  setOffSet,
  newProductData,
  isLoader
}) => {
  const imageRefs = useRef({});
  const { theme } = useContext(ThemeContext);

  const [copiedASIN, setCopiedASIN] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageModal, setImageModal] = useState(false);
  const [imageLoading, setImageLoading] = useState({});

  useEffect(() => {
    const initialLoadingState = {};
    newProductData.forEach((item) => {
      initialLoadingState[item.name] = true;
    });
    setImageLoading(initialLoadingState);
  }, [newProductData]);

  const handleScroll = (e) => {
    const tolerance = 1;
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= tolerance;
    if (bottom && newProductData.length !== 0 && !isLoader) {
      setOffSet((prev) => prev + 10);
    }
  };

  const copyAsin = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setCopiedASIN(value);
      setTimeout(() => {
        setCopiedASIN(null);
      }, 500);
    });
  };

  const handleCopy = (item, heading) => {
    if (heading.title === "asin" || heading.title === "bsr") {
      copyAsin(item[heading.value]);
    }
  };

  const checkColor = (value) => {
    if (value >= 10) {
      return "!text-marginGreenText !bg-marginGreenBg";
    } else if (value <= 10 && value >= 2) {
      return "!text-marginOrangeText !bg-marginOrangeBg";
    } else {
      return "!text-marginRedText !bg-marginRedBg";
    }
  };

  const getColorClass = (item, heading) => {
    switch (heading.title) {
      case "margin":
      case "profit":
        return checkColor(item["margin"]);
      case "margin 30d":
      case "profit 30d":
        return checkColor(item["margin_30d"]);
      default:
        return "";
    }
  };

  const productClass = (heading, item) => {
    if (heading.title === "bsr" || heading.title === "asin") {
      return "cursor-pointer text-textTitle bg-background border-[1px] border-borderBottom rounded shadow";
    }
    if (
      ["margin", "profit", "margin 30d", "profit 30d"].includes(heading.title)
    ) {
      return `${getColorClass(item, heading)} rounded`;
    } else {
      return "text-textTitle bg-background border-[1px] border-borderBottom rounded shadow";
    }
  };

  const productContent = (item, heading) => {
    return (
      <div key={`${heading.title}`} className="text-left min-w-20">
        <p className="subtitle text-[11px] uppercase flex">
          {heading.title}
          {(heading.title === "asin" || heading.title === "bsr") && (
            <>
              {copiedASIN === item[heading.value] ? (
                <span className="ml-2 text-green-500 text-[10px] capitalize">
                  Kopiert!
                </span>
              ) : (
                <img
                  className="ml-2 cursor-pointer"
                  src={"/static/copy-icon.svg"}
                  alt="copy_icon"
                  width={8}
                  height={10}
                />
              )}
            </>
          )}
        </p>
        <div className=" bg-primarybgColor rounded text-center shadow">
          <span
            className={`block truncate p_detail px-2 py-1 text-[12px] text-primarytextColor
                         ${productClass(heading, item)}`}
            onClick={() => handleCopy(item, heading)}
          >
            {currencyFormatter(item[heading.value])}
            {valueSign(heading.value)}
          </span>
        </div>
      </div>
    );
  };

  const handleChartHover = (item) => {
    setSelectedProduct(item);
    setImageModal(true);
  };

  const handleChartHoverLeave = () => {
    setSelectedProduct(null);
    setImageModal(false);
  };

  const selectedProductImg =
    theme === "dark"
      ? selectedProduct?.graph_img.dark
      : selectedProduct?.graph_img.white;

  const handleImageLoad = (item) => {
    setImageLoading((prev) => ({
      ...prev,
      [item.name]: false
    }));
  };

  const handleImageError = (item) => {
    setImageLoading((prev) => ({
      ...prev,
      [item.name]: false
    }));
  };

  return (
    <div
      onScroll={handleScroll}
      className={`product-section text-[13px] md:w-auto card-bottom z-0 }`}
    >
      <div className="list gap-6">
        {productData.map((item, i) => {
          const buyUrl = convertToDomain(item.buy_url);
          const sellUrl = convertToDomain(item.sell_url);

          const graphImg =
            theme === "dark"
              ? item.graph_img.dark || "static/NotAvailableChart-Dark.png"
              : item.graph_img.white || "static/NotAvailableChart-White.png";

          return (
            <div key={`${item.name}-${i}`} className="grid grid-cols-1">
              <div className="grid grid-cols-12 items-center rounded-2xl border-[1px] border-borderBottom p-3 shadow md:flex-row flex-col card-left-product ">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-10">
                  <div className="flex gap-4 items-center md:flex-row flex-col">
                    <div className="right-div pr-5 px-3">
                      <img
                        src={item.img}
                        alt="product_img"
                        className="md:h-[130px] h-[150px] w-[150px] object-contain bg-white rounded-2xl"
                      />
                    </div>
                    <div className="left-div ">
                      <div className="text-textTitle list-none capitalize flex flex-wrap items-center">
                        <div className="flex flex-wrap items-center justify-between lg:gap-2">
                          <p
                            className="text-[16px] font-medium text-ellipsis"
                            id="title"
                          >
                            {item.name.length > 65
                              ? item.name.slice(0, 65) + "..."
                              : item.name}
                          </p>

                          <p className="text-[13px] text-textPrimary/50 xl:ml-8">
                            {item.timestamp}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="flex gap-4 flex-wrap">
                          {productHeading
                            .slice(0, 5)
                            .map((heading) => productContent(item, heading))}
                        </div>
                        <div className="flex gap-4 mt-1 flex-wrap">
                          {productHeading
                            .slice(5, 8)
                            .map((heading) => productContent(item, heading))}
                          <div className="text-left">
                            <p className="subtitle text-[11px] uppercase">
                              buy
                            </p>
                            <div className="bg-gradient-to-br from-primary to-main rounded text-center">
                              <button
                                className="w-full truncate p_detail px-2 py-1 text-[12px]  text-text border-none"
                                onClick={() =>
                                  window.open(item.buy_url, "_blank")
                                }
                              >
                                {buyUrl}
                              </button>
                            </div>
                          </div>
                          <div className="text-left">
                            <p className="subtitle text-[11px] uppercase">
                              sell
                            </p>
                            <div className="rounded bg-secondary border-[1px] border-primary text-center">
                              <button
                                className="w-full truncate file:p_detail px-2 py-1 text-[12px] text-primary"
                                onClick={() =>
                                  window.open(item.sell_url, "_blank")
                                }
                              >
                                {sellUrl}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-2 flex justify-center relative z-[999]`}
                >
                  {imageLoading[item.name] ? (
                    <div className="flex w-full h-full items-center justify-center">
                      <i className="animate-spin bx bx-loader-alt text-4xl text-primary "></i>
                    </div>
                  ) : null}
                  <img
                    ref={(el) => (imageRefs.current[item.name] = el)}
                    className={`h-full w-96 cursor-pointer ${
                      imageLoading[item.name] ? "hidden" : ""
                    }`}
                    src={graphImg}
                    alt="product_img"
                    onLoad={() => handleImageLoad(item)}
                    onError={() => handleImageError(item)}
                    onClick={() => {
                      if (window.innerWidth < 1280) handleChartHover(item);
                    }}
                    onMouseEnter={() => {
                      if (window.innerWidth >= 1280) handleChartHover(item);
                    }}
                    onMouseLeave={() => {
                      if (window.innerWidth >= 1280) handleChartHoverLeave();
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center items-center mt-2 mb-20">
          {isLoader ? (
            <i className="animate-spin bx bx-loader-alt text-4xl text-primary"></i>
          ) : newProductData.length === 0 ? (
            <p className="text-textTitle">Keine weiteren Daten gefunden</p>
          ) : null}
        </div>
      </div>
      <CommonModal
        isOpen={imageModal}
        closeModal={() => setImageModal(false)}
        overlayWidth={window.innerWidth < 1280 ? "100%" : "80%"}
        isChartModal
      >
        <div className="p-4 w-[300px] sm:w-[510px] overflow-auto">
          <div
            onClick={() => setImageModal(false)}
            className="p-4 pr-0 pt-0 flex justify-end items-center cursor-pointer"
          >
            <img
              src={
                theme === "dark"
                  ? "static/close-1.svg"
                  : "static/LightMode/close-2.svg"
              }
              width={15}
              alt="close"
            />
          </div>
          <img
            className="h-full w-[480px] "
            src={selectedProductImg}
            alt="product_img"
          />
        </div>
      </CommonModal>
    </div>
  );
};
export default ProductContent;
