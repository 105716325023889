import React from "react";
import Overview from "../containers/Overview";
import Layout from "../layout/Layout";
import Footer from "../components/Footer";

const Dashboard = () => {
  return (
    <Layout>
      <Overview />
      <Footer />
    </Layout>
  );
};

export default Dashboard;
