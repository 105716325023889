import React from "react";
import Layout from "../layout/Layout";
import Footer from "../components/Footer";
import SubHeader from "../components/SubHeader";
import GuideCard from "../containers/GuideCard";
const Guide = () => {
  return (
    <>
      <Layout>
        <section className="home-section bg-background">
          <div className="text  border-b-[1px] border-borderBottom">
            <SubHeader date>
              <p className="title text-textTitle font-semibold tracking-wide text-[20px]">
                Willkommen
                <span className="text-primary ml-1 font-semibold tracking-wide">
                  Max
                </span>
                <span className="ml-1">👋</span>
              </p>
            </SubHeader>
          </div>
          <GuideCard />
        </section>
        <Footer />
      </Layout>
    </>
  );
};
export default Guide;
