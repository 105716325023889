import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../containers/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeOverview } from "../redux/slices/authSlice";

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const authDetails = useSelector((state) => state.auth.authData);
  const authCodeStatus = useSelector((state) => state.auth.status);
  const homeDetails = useSelector((state) => state.auth.homeData);

  useEffect(() => {
    if (
      (authCodeStatus === "authorized" ||
        authDetails?.status === "authorized") &&
      !homeDetails?.name
    ) {
      dispatch(fetchHomeOverview());
    }
  }, [authDetails, homeDetails]);

  return (
    <>
      <Header />
      <main className="overflow-hidden ml-[78px]">
        <Sidebar />
        {children}
      </main>
    </>
  );
};

export default Layout;
