import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  changeFilterAPI,
  getCurrentFilterAPI,
  getFilterAPI
} from "../../lib/api/api";

export const fetchFilter = createAsyncThunk(
  "filter/getFilterAPI",
  async (application, { dispatch }) => {
    const response = await getFilterAPI(application);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const currentFilter = createAsyncThunk(
  "currentFilter/getCurrentFilterAPI",
  async (application, { dispatch }) => {
    const response = await getCurrentFilterAPI(application);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

export const changeFilter = createAsyncThunk(
  "changeFilter/changeFilterAPI",
  async (filterData, { dispatch }) => {
    const { application, type, attribute } = filterData;
    const response = await changeFilterAPI(application, type, attribute);
    const status = response?.status;

    if (status === "unauthorized") {
      dispatch(setAuthStatus(status));
    }
    return response;
  }
);

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    filter: null,
    status: "idle",
    error: null,
    currentFilter: null,
    currentFilterStatus: "idle",
    currentFilterError: null,
    changeFilter: null,
    changeFilterStatus: "idle",
    changeFilterError: null,
    authStatus: null
  },
  reducers: {
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFilter.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filter = action.payload;
      })
      .addCase(fetchFilter.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(currentFilter.pending, (state) => {
        state.currentFilterStatus = "loading";
      })
      .addCase(currentFilter.fulfilled, (state, action) => {
        state.currentFilterStatus = "succeeded";
        state.currentFilter = action.payload;
      })
      .addCase(currentFilter.rejected, (state, action) => {
        state.currentFilterStatus = "failed";
        state.currentFilterError = action.error.message;
      })
      .addCase(changeFilter.pending, (state) => {
        state.changeFilterStatus = "loading";
      })
      .addCase(changeFilter.fulfilled, (state, action) => {
        state.changeFilterStatus = "succeeded";
        state.changeFilter = action.payload;
      })
      .addCase(changeFilter.rejected, (state, action) => {
        state.changeFilterStatus = "failed";
        state.changeFilterError = action.error.message;
      });
  }
});

export const { setAuthStatus } = filterSlice.actions;
export default filterSlice.reducer;
