import React, { useEffect } from "react";
import ProductList from "../containers/ProductList";
import { useDispatch } from "react-redux";
import { resetA2AProducts } from "../redux/slices/a2aProductSlice";

const DealHub = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetA2AProducts());
  }, []);


  return (
    <ProductList
      application="dealhub"
      productType="dealhub_products"
      header="Deal"
      subHeader="Hub"
      filterSubHeader="Deine Filter für den Deal-Hub"
      noProductbtnTitle="Neu laden"
      noProductDesc="Wir konnten keine Produkte finden, möglicherweise liegt dies an deinen Filtern. Wähle alle Filter ab oder lade diese Seite neu!"
      isAsinsModalHide={true}
    />
  );
};

export default DealHub;
